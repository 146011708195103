import { Link } from 'react-router-dom';

import ShippingInfo from '../ShippingInfo';
import BackArrow from '../../../../images/circle-arrow-left-gray.svg';
import { ROUTE_TAKE_BACK_BAGS } from '../../../../config/routes';

const ShippingDetails = ({
  shippingMethod,
  code,
  expirationLabelDate,
  qrCodeImgUrl,
  printLabelUrl,
  address,
  isAuthFreeFlow = false,
  recyclingProductType,
  storeConfig = {},
}) => (
  <div className="ShippingDetails">
    <div className="ShippingDetails__container">
      {
        !isAuthFreeFlow && (
          <div className="ShippingInfo__container--backbutton">
            <Link to={ROUTE_TAKE_BACK_BAGS} className="ShippingInfo__container--backbutton--link">
              <img src={BackArrow} alt="back-arrow" />
              GO TO ALL TBBS
            </Link>
          </div>
        )
      }
      <div className="ShippingInfo__container--title">
        {shippingMethod?.configuration?.shippingTitle}
      </div>
      <div className="ShippingDetails__container--content">
        <ShippingInfo
          code={code}
          shippingMethod={shippingMethod}
          expirationLabelDate={expirationLabelDate}
          qrCodeImgUrl={qrCodeImgUrl}
          printLabelUrl={printLabelUrl || qrCodeImgUrl}
          address={address}
          recyclingProductType={recyclingProductType}
          storeConfig={storeConfig}
        />
      </div>
    </div>
  </div>
);

export default ShippingDetails;
