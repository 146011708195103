import classNames from 'classnames';
import SortedBy from '../SortedBy';
import TitleSectionAccount from '../TitleSectionAccount';
import ButtonBack from '../ButtonBack';
import { isMobile } from '../../../../hooks/useBreakPoint';
import style from './ListHeaders.module.scss';

const {
  titleContainer,
  buttonText,
} = style;

const BUTTON_BACK_TEXT = 'BACK TO MY ACCOUNT';

const ListHeaders = ({
  title,
  subTitle,
  actionTitle,
  sortedText,
  backButtonText = BUTTON_BACK_TEXT,
  returnActionRoute,
  className,
  titleSectionClassName,
}) => {
  const mobile = isMobile();

  return (
    <div className={classNames(titleContainer, className)}>
      {!mobile && returnActionRoute && (
        <ButtonBack
          route={returnActionRoute}
        >
          {backButtonText && (
            <div className={buttonText}>
              {backButtonText}
            </div>
          )}
        </ButtonBack>
      )}
      <TitleSectionAccount
        title={title}
        subTitle={subTitle}
        actionTitle={actionTitle}
        className={titleSectionClassName}
      />
      {sortedText && <SortedBy sortedText={sortedText} />}
    </div>
  );
};

export default ListHeaders;
