import * as React from 'react';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { styled } from '@mui/material';

const StyledBottomNavigationAction = styled((props) => <BottomNavigationAction {...props} />)({
  transitionProperty: 'color',
  minWidth: '75px',
  '.MuiBottomNavigationAction-label': {
    color: 'var(--trashie-ui-faded-black-70, #696969)',
    fontFamily: 'GT Maru',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '100%',
    marginTop: '16px',
    '&.Mui-selected': {
      color: 'var(--trashie-primary-code-blue)',
      fontSize: '11px',
    },
  },
});

export default StyledBottomNavigationAction;
