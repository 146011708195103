import { useEffect, useState, useRef } from 'react';

import Input from '../../../../global/Input';
import DotsLoader from '../../../../global/DotsLoader';
import InfoSection from '../../../../global/InfoSection/InfoSection';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import { qrCodeFormatValidator } from '../../../../utils/qrCodeFormatValidator';
import Dialog from '../../../../global/Mui/Dialog';

import './QrCodeForm.scss';

const DEFAULT_ZEROS = '000000';
const QrCodeForm = ({ onCancel, onClick }) => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(DEFAULT_ZEROS);
  const [isValid, setIsValid] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const typingTimeoutRef = useRef(null);

  const handleQrCode = async () => {
    setLoading(true);
    setShowModal(true);
    setLoading(false);
  };

  const handleChange = (value) => {
    let inputValue = value;
    setIsValid(true);

    if (inputValue === '') {
      setCode(DEFAULT_ZEROS);
      setIsValid(false);
      return;
    }

    const numericPart = inputValue.replace(/\D/g, '').slice(0, 13);
    const textPart = inputValue.slice(13).replace(/[^a-z]/g, '');

    let paddedNumericPart = numericPart.padStart(6, '0');

    if (paddedNumericPart.length > 13) {
      paddedNumericPart = paddedNumericPart.slice(0, 13);
    }

    if (paddedNumericPart.length === 13) {
      if (inputValue.includes('-')) {
        inputValue = `${paddedNumericPart}-${textPart}`;
      } else if (textPart && !inputValue.includes('-')) {
        inputValue = `${paddedNumericPart}-${textPart}`;
      } else {
        inputValue = textPart ? `${paddedNumericPart}-${textPart}` : paddedNumericPart;
      }
    } else {
      inputValue = paddedNumericPart;
    }

    setCode(inputValue);

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setIsValid(qrCodeFormatValidator(inputValue));
    }, 2000);
  };

  useEffect(() => () => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
  }, []);

  return (
    <div className="QrCodeForm">
      <Dialog open={showModal} onClose={() => setShowModal(false)} title="Invalid code">
        <div className="QrCodeForm__modalContent">
          <div className="QrCodeForm__modalContent--title">
            JUST IN CASE, LET’S DOUBLE CHECK THE CODE, IS IT RIGHT?
          </div>
          <div className="QrCodeForm__modalContent--qrcode">
            {code}
          </div>
          <div className="QrCodeForm__modalContent--ctas">
            <Button
              type={BUTTON_TYPE.QUATERNARY}
              size={BUTTON_SIZE.LARGE}
              onClick={() => onClick(code)}
              disabled={loading}
            >
              {loading ? (
                <DotsLoader />
              ) : (
                'YES, CONTINUE')}
            </Button>

            <Button
              type={BUTTON_TYPE.QUINARY}
              size={BUTTON_SIZE.LARGE}
              onClick={() => setShowModal(false)}
              disabled={loading}
            >
              OOPS, NEEDS A FIX
            </Button>
          </div>
        </div>
      </Dialog>

      <InfoSection
        titleText="Enter your bag’s CODE"
        descriptionText="Located in the lower right corner of your bag, it usually starts with various zeros."
      />
      <div className="QrCodeForm__inputContainer">
        <Input
          type="text"
          id="qrCode"
          value={code}
          errorMessage={!isValid ? 'Your code must have at least 13 characters.' : null}
          onChange={handleChange}
          containerClassName="QrCodeForm__inputContainer--input"
        />
      </div>
      <Button
        type={BUTTON_TYPE.QUATERNARY}
        size={BUTTON_SIZE.LARGE}
        onClick={handleQrCode}
        disabled={!qrCodeFormatValidator(code) || loading}
      >
        {loading ? (
          <DotsLoader />
        ) : (
          'CONTINUE')}
      </Button>
      <div className="QrCodeForm__option">
        <p className="QrCodeForm__option--text">
          Code not working?
        </p>
        <Button
          type={BUTTON_TYPE.LINK_QUINARY}
          onClick={onCancel}
        >
          CONTACT US FOR HELP
        </Button>
      </div>
    </div>
  );
};
export default QrCodeForm;
