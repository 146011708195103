import {
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  TRACKER_IDS,
} from '../config/tracker';

export const getNextButtonStyles = (storeConfig) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    background: registerTbbFlow?.nextButton?.bgColor,
    color: registerTbbFlow?.nextButton?.textColor,
    border: registerTbbFlow?.nextButton?.bgColor,
  };
};

export const getOptionButtonStyles = (storeConfig, border = false) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    color: registerTbbFlow?.nextButton?.bgColor,
    ...(border && { borderColor: registerTbbFlow?.nextButton?.bgColor }),
  };
};

export const REDEMPTION_CODE_STATUS_IDS = {
  CLAIMED: 1,
  RETURNED: 2,
  PENDING: 3,
  BLOCKED: 4,
};

export const getCouponStyle = (storeConfig) => {
  const {
    isTrashie,
    registerTbbFlow,
  } = storeConfig;

  if (isTrashie) {
    return {};
  }

  return {
    color: registerTbbFlow.rewardStep?.cardTextColor,
    background: registerTbbFlow.rewardStep?.cardBgColor,
    border: 'none',
  };
};

export const RECYCLING_PRODUCT_TYPES = {
  TEXTIL: 'TEXTILES_TBB',
  TECH: 'TECH_BOX',
};

export const RECYCLING_PRODUCT_ITEMS = [
  { label: 'Amazon Alexa, Google Assistant' },
  { label: 'Cable box, Roku box, Apple TV' },
  { label: 'Cables, power cords, chargers' },
  { label: 'Cell or smart phone' },
  { label: 'Circuit boards' },
  { label: 'Digital Cameras' },
  { label: 'Laptop, Chromebook, or tablet' },
  { label: 'Modem, or router' },
];

export const RECYCLING_PRODUCT_ITEMS_NOT_ALLOWED = [
  'External loose batteries (household alkaline or loose lithium)',
  'Airpods or headphones',
  'Smartwatches',
  'Loose hard drives',
  'Keyboards & Mouse',
  'Inkjet Cartr',
  'Light Bulbs (ALL)',
  'Speakers, microphones',
  'Apple Airtags',
  'Toys',
  'Electronic Thermometer',
  'Electric Toothbrushes',
  'Hazardous Waste Pressurized canisters, Paint, Chemicals, Oils, etc.',
  'Medical / Biohazard Waste',
  'Smoke and Carbon monoxide detectors',
  'Radios',
  'Thermostats',
  'Clocks',
  'Kitchen Appliances',
  'Mixed magnetic tapes, CD/DVD, and DLT/LTO, Cassettes',
  'Calculators',
];

export const TABS = {
  WELCOME: 'welcome',
  INVALID: 'invalid',
  PIN: 'pin',
  LOGIN: 'login',
  TBB: 'tbb',
  SELECT_ADDRESS: 'select-address',
  ENTER_NEW_ADDRESS: 'enter-new-address',
  SELECT_SHIPPING_METHOD: 'select-shipping-method',
  SHIPPING_METHOD_GENERATION_ERROR: 'shipping-method-generation-error',
  QR: 'qr',
  COUPON: 'coupon',
  MESSAGE: 'message',
  SHIPPING_REWARD: 'shipping-reward',
  SHIPPING_DETAILS: 'shipping-details',
  EMAIL_FORM: 'email-form',
  RECYCLING_LIST: 'recycling-list',
  DEVICE_BATTERY_WARNING: 'device-battery-warning',
  STICKERS_ADVICE: 'stickers-advice',
  QR_CODE_FORM: 'qr-code-form',
};

export const isTechRecyclingProductType = recyclingProductType => (
  recyclingProductType === RECYCLING_PRODUCT_TYPES.TECH
);

export const isTextilRecyclingProductType = recyclingProductType => (
  recyclingProductType === RECYCLING_PRODUCT_TYPES.TEXTIL
);

export const getStepButtonTracker = () => {
  const {
    tbb: {
      stepButton: trackerStepButton,
    },
  } = TRACKER_IDS;

  return trackerStepButton;
};

export const getStepButtonTrackerProps = (
  stepTab,
  recyclingProductType,
  extraProps = {},
) => {
  const trackerStepButton = getStepButtonTracker();

  return {
    [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerStepButton),
    [POSTHOG_CAPTURE_ATTRIBUTES.TBB_STEP]: stepTab,
    [POSTHOG_CAPTURE_ATTRIBUTES.TBB_RECYCLING_PRODUCT_TYPE]: recyclingProductType,
    ...extraProps,
  };
};
