import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardSection from '../../../global/DashboardSection';
import { getGenericError } from '../../../utils/errors';
import { getRewards, getConfigurations } from '../../../utils/service';
import { REWARD_TYPE } from '../../../config/rewards';
import {
  ANCHOR_DEALS,
  CATEGORY_SEARCH_PARAM,
  REWARD_ID_ROUTE_PARAM,
  ROUTE_DEALS,
  ROUTE_DEAL_STORE_REWARD_ID,
  STORE_ID_ROUTE_PARAM,
} from '../../../config/routes';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import RedeemModal from '../../Reward/RedeemModal';
import { FLAGS } from '../../Rewards/utils';
import RewardItem from '../../Rewards/RewardItem';
import Slider, { ARROWS_VERTICAL_POSITION, SLIDER_TYPES } from '../../../global/Slider';

import './DealsPreview.scss';

const PREVIEW_PAGE_SIZE_DEFAULT = 10;
const PREVIEW_PAGE_SIZE_DAILY = 5;

const SLIDES_PER_PAGE_DEFAULT = 4;
const SLIDES_PER_PAGE_UNLOCK = 2;
const SLIDES_PER_PAGE_DAILY = 3;

export const PREVIEW_TYPES = {
  DEFAULT: 'default',
  STARTER: 'starter',
  DAILY: 'daily',
  HOT: 'hot',
  CATEGORY: 'category',
  UNLOCK: 'unlock',
};

const getFilters = ({ type, categoryId }) => {
  const filters = { exchangeValueMax: '0' };

  switch (type) {
    case PREVIEW_TYPES.STARTER:
      filters[FLAGS.IS_STARTER] = true;
      break;
    case PREVIEW_TYPES.HOT:
      filters[FLAGS.IS_HOT] = true;
      break;
    case PREVIEW_TYPES.DAILY:
      filters.sort = 'campaignEndDate';
      break;
    case PREVIEW_TYPES.CATEGORY:
      filters.categoryId = categoryId;
      break;
    case PREVIEW_TYPES.UNLOCK:
    case PREVIEW_TYPES.DEFAULT:
    default:
      break;
  }

  return filters;
};

const {
  dashboard: {
    instantDeals: {
      viewAllButton: trackerViewAllButton,
    },
  },
} = TRACKER_IDS;

const DealsPreview = ({
  type = PREVIEW_TYPES.DEFAULT,
  title,
  subTitle,
  category,
  withCta = true,
}) => {
  const history = useHistory();

  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [rewardProperties, setRewardProperties] = useState({});
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [redeemRewardItem, setRedeemRewardItem] = useState(null);
  const [openRedeemModal, setOpenRedeemModal] = useState(false);

  const {
    isUnlockType,
    isDailyType,
    isHotType,
    isCategoryType,
    pageSize,
    slidesPerPage,
  } = useMemo(() => {
    const unlockType = type === PREVIEW_TYPES.UNLOCK;
    const dailyType = type === PREVIEW_TYPES.DAILY;

    let previewPageSize = PREVIEW_PAGE_SIZE_DEFAULT;
    let slidesPerPageSize = SLIDES_PER_PAGE_DEFAULT;

    if (unlockType) {
      slidesPerPageSize = SLIDES_PER_PAGE_UNLOCK;
    } else if (dailyType) {
      previewPageSize = PREVIEW_PAGE_SIZE_DAILY;
      slidesPerPageSize = SLIDES_PER_PAGE_DAILY;
    }

    return {
      isUnlockType: unlockType,
      isDailyType: dailyType,
      isHotType: type === PREVIEW_TYPES.HOT,
      isCategoryType: type === PREVIEW_TYPES.CATEGORY,
      pageSize: previewPageSize,
      slidesPerPage: slidesPerPageSize,
    };
  }, [type]);

  const loadRewards = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: rewardsError,
    } = await getRewards({
      pageSize,
      page: 1,
      ...getFilters({ type, categoryId: category?.uid }),
    });

    if (rewardsError) {
      setRewards([]);
      setError(rewardsError.message || getGenericError());
      setIsLoading(false);
      return;
    }

    setRewards(data.rewards);
    setError('');
    setIsLoading(false);
  }, [type, pageSize]);

  const rewardsConfig = useCallback(async () => {
    setIsLoadingConfig(true);
    const { value, error: configError } = await getConfigurations('rewardProperties');
    if (!configError) {
      setRewardProperties(value);
    }
    setIsLoadingConfig(false);
  }, []);

  const handleOnClick = useCallback(() => {
    const categoryParam = isHotType
      ? FLAGS.IS_HOT
      : (isCategoryType && category?.routeName) || FLAGS.ALL;

    history.push({
      pathname: ROUTE_DEALS,
      search: new URLSearchParams({ [CATEGORY_SEARCH_PARAM]: categoryParam }).toString(),
    });
  }, [history]);

  const handleItemOnClick = useCallback((rewardItem, redeem = false) => {
    const { type: rewardItemType } = rewardItem;

    if (openRedeemModal) {
      return;
    }

    if (redeem && rewardItemType !== REWARD_TYPE.SEGMENTED_BY_PURCHASED_SKU) {
      setRedeemRewardItem(rewardItem);
      setOpenRedeemModal(true);
      return;
    }

    const relativePath = ROUTE_DEAL_STORE_REWARD_ID
      .replace(STORE_ID_ROUTE_PARAM, rewardItem.store?.id)
      .replace(REWARD_ID_ROUTE_PARAM, rewardItem.uid);

    history.push(relativePath);
  }, [history]);

  useEffect(() => {
    loadRewards();
    rewardsConfig();
  }, []);

  return (
    <DashboardSection
      anchor={`${ANCHOR_DEALS}-${type}`}
      title={title}
      subTitle={subTitle}
      onClick={withCta && !isDailyType ? handleOnClick : undefined}
      isLoading={isLoading || isLoadingConfig}
      error={error}
      noData={rewards.length ? undefined : 'No Deals available at the moment'}
      ctaTrackerProps={{
        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerViewAllButton),
        [POSTHOG_CAPTURE_ATTRIBUTES.SECTION]: type,
      }}
    >
      <div className="DealsPreview">
        <Slider
          type={isDailyType ? SLIDER_TYPES.DEFAULT : SLIDER_TYPES.OVERFLOW}
          autoplay={isDailyType}
          rewind={isDailyType}
          paginationDots={isDailyType}
          arrowsVerticalPosition={ARROWS_VERTICAL_POSITION.TOP}
          gap={32}
          slidesPerPage={slidesPerPage}
          slides={rewards.map(rewardItem => ({
            key: `deal-preview-${rewardItem.uid}`,
            slide: (
              <RewardItem
                key={`slide-deal-${rewardItem.uid}`}
                rewardItem={rewardItem}
                onClick={handleItemOnClick}
                rewardProperties={rewardProperties}
                withCta={!isUnlockType}
                trackerProps={{
                  [POSTHOG_CAPTURE_ATTRIBUTES.SECTION]: type,
                }}
              />
            ),
          }))}
        />
      </div>
      <RedeemModal
        rewardItem={redeemRewardItem}
        showModal={openRedeemModal}
        onClose={() => setOpenRedeemModal(false)}
      />
    </DashboardSection>
  );
};

export default DealsPreview;
