import React from 'react';

import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../Button';
import ChevronLeft from '../../images/chevron-left.svg';
import ChevronRight from '../../images/chevron-right.svg';

import './ListPaginator.scss';

const getTotalPages = (totalItems, pageSize) => Math.ceil(totalItems / (pageSize || 1));

const getPages = (totalItems, pageSize, currentPage, pagesToShow = 4) => {
  const pages = [];
  const totalPages = getTotalPages(totalItems, pageSize);
  let firstPage = 1;
  let lastPage = totalPages;

  if (totalPages > pagesToShow) {
    if (currentPage === totalPages) {
      firstPage = currentPage - 3;
      lastPage = totalPages;
    } else if ((currentPage - 2) > 1) {
      firstPage = currentPage - 2;
      lastPage = currentPage + 1;
    } else {
      firstPage = 1;
      lastPage = pagesToShow;
    }
  }

  for (let i = firstPage; i <= lastPage; i++) {
    pages.push(i);
  }

  return pages;
};

/*
  <ListPaginator
    pageSize={10}
    totalItems={68}
    currentPage={2}
    isLoading={true | false}
    onPageSelection={(selectedPage) => { setPage(selectedPage); }}
  />
*/

const ListPaginator = ({
  totalItems,
  pageSize,
  currentPage,
  isLoading,
  onPageSelection,
}) => (
  <div className="ListPaginator">
    <Button
      className="ListPaginator__button"
      type={BUTTON_TYPE.SECONDARY}
      size={BUTTON_SIZE.MEDIUM}
      disabled={currentPage === 1 || isLoading}
      onClick={() => onPageSelection(currentPage - 1)}
    >
      <img src={ChevronLeft} alt="chevron left" />
      <span>Prev</span>
    </Button>
    <div className="ListPaginator__pages">
      {getPages(totalItems, pageSize, currentPage).map(page => (
        <Button
          key={page}
          className="ListPaginator__pages--button"
          type={BUTTON_TYPE.LINK}
          size={BUTTON_SIZE.MEDIUM}
          disabled={page === currentPage || isLoading}
          onClick={() => onPageSelection(page)}
        >
          {page}
        </Button>
      ))}
    </div>
    <Button
      className="ListPaginator__button"
      type={BUTTON_TYPE.SECONDARY}
      size={BUTTON_SIZE.MEDIUM}
      disabled={currentPage === getTotalPages(totalItems, pageSize) || isLoading}
      onClick={() => onPageSelection(currentPage + 1)}
    >
      <span>Next</span>
      <img src={ChevronRight} alt="chevron right" />
    </Button>
  </div>
);

export default React.memo(ListPaginator);
