import { useMemo } from 'react';
import classNames from 'classnames';

import Markdown from 'react-markdown';
import CollapsableSection from '../../../../../global/CollapsableSection';
import Card from '../../../../../global/Mui/Card';
import ShippingProviderLocations from '../ShippingProviderLocations';

import './ShippingProviderSelectItem.scss';

function ShippingProviderSelectItem({
  index,
  shippingProvider,
  selectedIndex,
  address,
  onSelect,
  storeConfig,
}) {
  const {
    promotion,
    logoURL,
    name,
    description,
    configuration: {
      details,
      locations,
      storesUrl,
    },
  } = useMemo(() => shippingProvider, [shippingProvider]);

  const isCurrentItemSelected = useMemo(() => (index === selectedIndex), [index, selectedIndex]);

  return (
    <>
      <Card
        onClick={() => onSelect(index, name)}
        className={classNames('ShippingProviderSelectItem', {
          selectedCardContainer: isCurrentItemSelected,
        })}
      >
        <div className="ShippingProviderSelectItem__header">
          <img src={logoURL} alt={`${name} logo`} className="shippingProviderImage" />
          <div className="shippingProviderDescriptionContainer">
            <div className="shipingProviderNameContainer">
              <p className="shipingProviderNameText">
                {description}
              </p>
              <input
                type="radio"
                className="radioButton"
                readOnly
                checked={isCurrentItemSelected}
                style={{
                  ...(isCurrentItemSelected && !storeConfig?.isTrashie && {
                    backgroundColor: storeConfig?.registerTbbFlow?.nextButton?.bgColor,
                  }),
                }}
              />
            </div>
            {promotion && (
              <p className="shipingProviderPromotionText">
                {`Earn ${promotion.amount} TrashieCash™* with ${name}`}
              </p>
            )}
          </div>
        </div>
        {details && (
          <div className="ShippingProviderSelectItem__details">
            <Markdown className="ShippingProviderSelectItem__markdown">
              {details}
            </Markdown>
          </div>
        )}
        {
          storesUrl && (
            <a
              href={storesUrl}
              target="_blank"
              rel="noreferrer"
              className="ShippingProviderSelectItem__storesLink"
            >
              view stores
            </a>
          )
        }
        {locations?.enabled && (
          <CollapsableSection
            title={locations.description}
            titleSize="small"
            content={(
              <ShippingProviderLocations
                locations={locations}
                address={address}
              />
            )}
          />

        )}
      </Card>
      {promotion && (
        <p className="shipingProviderPromotionnNote">
          * Gets awarded upon bag registration.
        </p>
      )}
    </>
  );
}

export default ShippingProviderSelectItem;
