import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardSection from '../../../global/DashboardSection';
import { getGenericError } from '../../../utils/errors';
import { getRewards, getConfigurations } from '../../../utils/service';
import {
  ANCHOR_REWARDS,
  REWARD_ID_ROUTE_PARAM,
  ROUTE_REWARDS,
  ROUTE_REWARDS_STORE_REWARD_ID,
  STORE_ID_ROUTE_PARAM,
} from '../../../config/routes';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import RewardItem from '../RewardItem';
import Slider, { ARROWS_VERTICAL_POSITION, SLIDER_TYPES } from '../../../global/Slider';

import './RewardsPreview.scss';
import { FLAGS } from '../utils';

const PREVIEW_PAGE_SIZE = 10;

export const PREVIEW_TYPES = {
  DEFAULT: 'default',
  HOT: 'hot',
  CATEGORY: 'category',
};

const getFilters = ({ type, categoryId }) => {
  const filters = { exchangeValueMin: '1' };

  switch (type) {
    case PREVIEW_TYPES.HOT:
      filters[FLAGS.IS_HOT] = true;
      break;
    case PREVIEW_TYPES.CATEGORY:
      filters.categoryId = categoryId;
      break;
    case PREVIEW_TYPES.DEFAULT:
    default:
      break;
  }

  return filters;
};

const {
  dashboard: {
    trashiecashRewards: {
      viewAllButton: trackerViewAllButton,
    },
  },
} = TRACKER_IDS;

const RewardsPreview = ({
  title = 'Premium Rewards',
  type = PREVIEW_TYPES.DEFAULT,
  category,
}) => {
  const history = useHistory();

  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [rewardProperties, setRewardProperties] = useState({});
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);

  const isDefaultType = useMemo(() => (
    type === PREVIEW_TYPES.DEFAULT
  ), [type]);

  const loadRewards = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: rewardsError,
    } = await getRewards({
      pageSize: PREVIEW_PAGE_SIZE,
      page: 1,
      ...(getFilters({ type, categoryId: category?.uid })),
    });

    if (rewardsError) {
      setRewards([]);
      setError(rewardsError.message || getGenericError());
      setIsLoading(false);
      return;
    }

    setRewards(data.rewards);
    setError('');
    setIsLoading(false);
  }, []);

  const rewardsConfig = useCallback(async () => {
    setIsLoadingConfig(true);
    const { value, error: configError } = await getConfigurations('rewardProperties');
    if (!configError) {
      setRewardProperties(value);
    }
    setIsLoadingConfig(false);
  }, []);

  const handleOnClick = useCallback((rewardItem) => {
    const relativePath = ROUTE_REWARDS_STORE_REWARD_ID
      .replace(STORE_ID_ROUTE_PARAM, rewardItem.store?.id)
      .replace(REWARD_ID_ROUTE_PARAM, rewardItem.uid);

    history.push(relativePath);
  }, [history]);

  useEffect(() => {
    loadRewards();
    rewardsConfig();
  }, []);

  return (
    <DashboardSection
      anchor={ANCHOR_REWARDS}
      title={title}
      subTitle={isDefaultType ? 'You’ve earned it — redeem TrashieCash™ for something awesome.' : undefined}
      onClick={() => history.push(ROUTE_REWARDS)}
      isLoading={isLoading || isLoadingConfig}
      error={error}
      noData={rewards.length ? undefined : 'No Rewards available at the moment'}
      ctaTrackerProps={{
        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerViewAllButton),
      }}
    >
      <div className="RewardsPreview">
        <Slider
          type={SLIDER_TYPES.OVERFLOW}
          autoplay={false}
          rewind={false}
          paginationDots={false}
          arrowsVerticalPosition={ARROWS_VERTICAL_POSITION.TOP}
          gap={32}
          slidesPerPage={4}
          slides={rewards.map(rewardItem => ({
            key: `reward-preview-${rewardItem.uid}`,
            slide: (
              <RewardItem
                key={`slide-reward-${rewardItem.uid}`}
                rewardItem={rewardItem}
                onClick={handleOnClick}
                rewardProperties={rewardProperties}
              />
            ),
          }))}
        />
      </div>
    </DashboardSection>

  );
};

export default RewardsPreview;
