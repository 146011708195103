import { useMemo } from 'react';

import HeavyDollar from '../../../images/heavy-dollar-sign.svg';
import TrashieCoin from '../../../images/TC-icon-black.svg';
import PersonNoIcon from '../../../images/person-no-icon.svg';
import WarningIcon from '../../../images/warning-icon.svg';
import PaperClipIcon from '../../../images/paperclip-icon.svg';
import { RewardSummaryItems } from '../../Reward/RewardContent';

import './GiftCardContent.scss';

const GiftCardContentSummary = ({
  title,
  brandTitle,
  value,
  exchangeValue,
}) => {
  const summaryItems = useMemo(() => [
    {
      id: 'giftCardDescription',
      icon: HeavyDollar,
      label: `$${value} gift card to ${brandTitle}`,
      enabled: Boolean(value && brandTitle),
    },
    {
      id: 'price',
      icon: TrashieCoin,
      label: `Get it for ${`${exchangeValue} TrashieCash™`}`,
      enabled: Boolean(exchangeValue),
    },
    {
      id: 'cancel',
      icon: PersonNoIcon,
      label: 'Claimed gift cards can’t be cancelled, changed, or exchanged.',
      enabled: true,
    },
    {
      id: 'refundable',
      icon: WarningIcon,
      label: 'The TrashieCash™ exchanged for a Gift Card is non-refundable.',
      enabled: true,
    },
    {
      id: 'trashie_terms',
      icon: PaperClipIcon,
      label: 'TrashieCash™ Terms and Conditions apply and are subject to change.',
      enabled: true,
    },
  ], [brandTitle, exchangeValue, value]);

  return (
    <div className="GiftCardContentSummary">
      {title ? (
        <p className="GiftCardContentSummary__title">{title}</p>
      ) : (
        <p className="GiftCardContentSummary__message">Please also consider:</p>
      )}
      <RewardSummaryItems summaryItems={summaryItems} />
    </div>
  );
};

export default GiftCardContentSummary;
