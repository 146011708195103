import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { ROUTE_MY_ACCOUNT, ROUTE_SETTINGS } from '../../config/routes';
import Button, { BUTTON_TYPE } from '../../global/Button/Button';
import { getGenericError } from '../../utils/errors';
import { updateUser } from '../../utils/service';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { useAlert } from '../../../providers/AlertProvider';
import Loader from '../../../global/Loader';
import ListHeaders from '../../global/ListHeaders';
import { isMobile } from '../../../../hooks/useBreakPoint';
import ButtonBack from '../../global/ButtonBack/ButtonBack';
import Input from '../../global/InputNew';
import EditIcon from '../../images/edit.svg';

import './Profile.scss';

const backButtonText = 'GO TO MY ACCOUNT';
const accountDetailsTitle = 'Account details';

const Profile = () => {
  const { userData: fbUserData, isLoading: isLoadingFirebaseAuth } = useFirebaseAuth();
  const history = useHistory();
  const setAlert = useAlert();
  const mobile = isMobile();

  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasLoaded, setHasLoaded] = useState(false);

  const handleOnNameChange = useCallback((newValue) => {
    setError('');
    setUserData(prevUserData => ({ ...prevUserData, firstName: newValue }));
  }, []);

  const handleOnLastNameChange = useCallback((newValue) => {
    setError('');
    setUserData(prevUserData => ({ ...prevUserData, lastName: newValue }));
  }, []);

  const sendProfile = useCallback(async (formState) => {
    setIsLoading(true);

    const {
      data: updateUserData,
      error: updateUserError,
    } = await updateUser(fbUserData.uid, formState);

    if (updateUserError) {
      setError(updateUserError);
      setIsLoading(false);
    } else {
      setUserData(updateUserData);
      setError('');
      history.go(0);
    }
  }, [fbUserData]);

  const handleSubmit = useCallback(() => {
    sendProfile({ firstName: userData.firstName, lastName: userData.lastName });
  }, [userData]);

  const handlePasswordClick = useCallback(() => {
    history.push(ROUTE_SETTINGS);
  }, []);

  useEffect(() => {
    if (isLoadingFirebaseAuth) {
      return;
    }

    if (fbUserData?.error) {
      setAlert({
        type: 'notification',
        message: getGenericError(),
        error,
      });
      setUserData({});
    } else {
      setUserData({ ...fbUserData });
    }

    setHasLoaded(true);
  }, [fbUserData, isLoadingFirebaseAuth]);

  if (isLoading || isLoadingFirebaseAuth || !hasLoaded) {
    return (
      <Loader className="Profile__loader" />
    );
  }

  return (
    <div className="Profile">
      <div className="Profile__content">
        {!mobile && (
          <div className="Profile__header">
            <ButtonBack className="Profile__back-button" route={ROUTE_MY_ACCOUNT}>
              {backButtonText}
            </ButtonBack>
            <ListHeaders titleSectionClassName="Profile__list-headers" title={accountDetailsTitle} />
          </div>
        )}
        <div className="Profile__container">
          <div className="Profile__container-header">Personal info</div>
          <div className="Profile__pane">
            <div className="Profile__form">
              <Input
                type="text"
                id="name"
                label="First name"
                placeholder="Enter your first name…"
                containerClassName="Profile__form-item"
                value={userData.firstName}
                onChange={handleOnNameChange}
                errorMessage={Boolean(error)}
              />
              <Input
                type="text"
                id="lastName"
                label="Last name"
                placeholder="Enter your last name…"
                containerClassName="Profile__form-item"
                value={userData.lastName}
                errorMessage={error}
                onChange={handleOnLastNameChange}
              />
              <Button
                type={BUTTON_TYPE.QUATERNARY}
                onClick={handleSubmit}
                disabled={!userData.firstName || !userData.lastName || error}
              >
                UPDATE
              </Button>
            </div>
          </div>
        </div>
        <div className="Profile__container">
          <div className="Profile__container-header">Password</div>
          <Button
            type={BUTTON_TYPE.SENARY}
            className={classNames('Profile__pane', ' Profile__password')}
            onClick={handlePasswordClick}
          >
            <div className="Profile__password-content">
              <div className="Profile__password-text">**********</div>
              <img className="Profile__edit-icon" src={EditIcon} alt="edit" />
            </div>
          </Button>

        </div>
      </div>

    </div>
  );
};

export default Profile;
