import MyRewardsList from '../../../global/MyRewardsList/MyRewardsList';
import ZeroState from '../../../global/MyRewardsList/ZeroState';
import DashboardSection from '../../../global/DashboardSection/DashboardSection';
import MyGiftCardsList from '../../../global/MyGiftCardsList';

import IconGem from '../../../images/icon-gem.svg';
import WalletIcon from '../../../images/wallet-icon.svg';
import {
  ANCHOR_GIFT_CARDS,
  ANCHOR_REWARDS,
  ROUTE_GIFT_CARDS,
  ROUTE_REWARDS,
  ROUTE_WALLET_GIFT_CARDS,
  ROUTE_WALLET_REWARDS,
} from '../../../config/routes';

import './RewardsWallet.scss';

const RewardsWallet = ({
  isLoading,
  isLoadingGiftCards,
  listData,
  history,
  hasAccessToGiftCards,
  giftCardsListData,
}) => (
  <>
    <DashboardSection
      anchor={ANCHOR_REWARDS}
      title="Premium Rewards"
      onClick={listData.length ? () => history.push(ROUTE_WALLET_REWARDS) : undefined}
      isLoading={isLoading}
      noData={listData.length ? undefined : (
        <ZeroState
          icon={IconGem}
          title="Use your trashiecash on premium rewards"
          buttonLabel="Browse premium rewards"
          buttonRoute={ROUTE_REWARDS}
        />
      )}
      className="RewardsWallet__section"
      contained
    >
      <MyRewardsList showIsUsed={false} rewardsList={listData} />
    </DashboardSection>
    {Boolean(hasAccessToGiftCards || giftCardsListData.length) && (
      <DashboardSection
        anchor={ANCHOR_GIFT_CARDS}
        title="Gift Cards"
        onClick={giftCardsListData.length ? (
          () => history.push(ROUTE_WALLET_GIFT_CARDS)
        ) : undefined}
        isLoading={isLoadingGiftCards}
        noData={giftCardsListData.length ? undefined : (
          <ZeroState
            icon={WalletIcon}
            title="REDEEM TRASHIECASH ON AWESOME GIFT CARDS"
            buttonLabel="Browse all gift cards"
            buttonRoute={ROUTE_GIFT_CARDS}
          />
        )}
        className="RewardsWallet__section"
        contained
      >
        <MyGiftCardsList showIsUsed={false} giftCardsList={giftCardsListData} />
      </DashboardSection>
    )}
  </>
);

export default RewardsWallet;
