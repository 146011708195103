import SEARCH_TYPE from './searchType';

import {
  REWARD_ID_ROUTE_PARAM,
  ROUTE_DEALS,
  ROUTE_DEAL_STORE_REWARD_ID,
  ROUTE_REWARDS_STORE_REWARD_ID,
  STORE_ID_ROUTE_PARAM,
  ROUTE_REWARDS,
  ROUTE_GIFT_CARDS,
  ROUTE_GIFT_CARDS_BRAND_ID,
  BRAND_ID_ROUTE_PARAM,
} from '../config/routes';

import { FIXED_CATEGORIES, FIXED_DEALS_CATEGORIES } from '../views/Rewards/utils';
import { sortingOptions, dealSortingOptions } from './sorting';

const searchOptionsModal = {
  [SEARCH_TYPE.DEALS]: {
    sorting: dealSortingOptions,
    filter: { exchangeValueMax: '0' },
    categories: FIXED_DEALS_CATEGORIES,
    getRelativePath: ({ store, itemId }) => ROUTE_DEAL_STORE_REWARD_ID
      .replace(STORE_ID_ROUTE_PARAM, store)
      .replace(REWARD_ID_ROUTE_PARAM, itemId),
    pathName: ROUTE_DEALS,
  },
  [SEARCH_TYPE.REWARDS]: {
    sorting: sortingOptions,
    filter: {},
    categories: FIXED_CATEGORIES,
    getRelativePath: ({ store, itemId }) => ROUTE_REWARDS_STORE_REWARD_ID
      .replace(STORE_ID_ROUTE_PARAM, store)
      .replace(REWARD_ID_ROUTE_PARAM, itemId),
    pathName: ROUTE_REWARDS,
  },
  [SEARCH_TYPE.GIFT_CARDS]: {
    sorting: {},
    filter: {},
    categories: [],
    getRelativePath: ({ brandId }) => ROUTE_GIFT_CARDS_BRAND_ID
      .replace(BRAND_ID_ROUTE_PARAM, brandId),
    pathName: ROUTE_GIFT_CARDS,
  },
};

export default searchOptionsModal;
