import React from 'react';

import { Link } from 'react-router-dom';
import Card, { CARD_TYPE } from '../Rewards/Card';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../Button';
import LoadingBar from '../LoadingBar';
import { SUPPORT_URL } from '../../config/externalURL';
import { CARD_TYPES, GiftCardCover } from '../../views/GiftCardBrand/GiftCardContent';

import GeneralModal from '../GeneralModal';

import './MyRewardItemModal.scss';

const CONFIRMATION_MAIN_TEXT = 'MARK THIS AS USED?';
const CONFIRMATION_MAIN_TEXT_NOT_USED = 'MARK THIS AS NOT USED?';
const ERROR_MAIN_TEXT = 'AN ERROR OCCURRED. TRY AGAIN? ';
const ERROR_DESCRIPTION = 'If the issue persists,';
const ERROR_SUPORT = 'contact support';
const CANCEL = 'CANCEL';
const CONFIRM = 'CONFIRM';
const TRY_AGAIN = 'TRY AGAIN';

const errorDescription = () => (
  <>
    {ERROR_DESCRIPTION}
    {' '}
    <Link className="MyRewardItemModal__support" target="_blank" to={{ pathname: SUPPORT_URL }}>{ERROR_SUPORT}</Link>
  </>
);

const MyRewardItemModal = (
  { toggleModal,
    showModal,
    card,
    description,
    isError,
    onClick,
    isLoading,
    usedStatusReward,
    isGiftCard = false },
) => {
  const { logoUrl, headerLogoProperties, cardBgColor, uniqueLink } = card;
  return (
    <GeneralModal
      onClose={toggleModal}
      showModal={showModal}
      showModalHeader={false}
      showModalFooter={false}
      canClose
      showClose
    >
      <div className="MyRewardItemModal__body">
        <div className="MyRewardItemModal__card">
          {isGiftCard ? (
            <GiftCardCover
              logoUrl={logoUrl}
              uniqueUrl={uniqueLink}
              type={CARD_TYPES.DEFAULT}
            />
          ) : (
            <Card
              logoUrl={logoUrl}
              logoStyle={headerLogoProperties}
              backgroundColor={cardBgColor}
              type={CARD_TYPE.DEFAULT}
              cardLink={uniqueLink}
            />
          )}

        </div>
        <dir className="MyRewardItemModal__text">
          <span className="MyRewardItemModal__main-text">
            {isError && ERROR_MAIN_TEXT}
            {!isError &&
              (usedStatusReward ? CONFIRMATION_MAIN_TEXT_NOT_USED : CONFIRMATION_MAIN_TEXT)}
          </span>
          <span className="MyRewardItemModal__secondary-text">
            {isError ? errorDescription() : description}
          </span>
        </dir>

        {isLoading && <LoadingBar className="MyRewardItemModal__loader" />}
        {!isLoading && (
          <div className="MyRewardItemModal__buttons">
            <Button
              className="MyRewardItem__cancel-button"
              type={BUTTON_TYPE.QUINARY}
              size={BUTTON_SIZE.LARGE}
              onClick={toggleModal}
            >
              {CANCEL}
            </Button>
            <Button
              className="MyRewardItem__action-button"
              type={BUTTON_TYPE.QUATERNARY}
              size={BUTTON_SIZE.LARGE}
              onClick={onClick}
            >
              {isError ? TRY_AGAIN : CONFIRM}
            </Button>
          </div>
        )}
      </div>
    </GeneralModal>
  );
};

export default MyRewardItemModal;
