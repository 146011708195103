import { useMemo } from 'react';

import { isMobile } from './useBreakPoint';
import SEARCH_TYPE from '../components/trashie/utils/searchType';

const useSearchPlaceHolderText = (searchType) => {
  const isMobileValue = isMobile();
  return useMemo(() => {
    if (!isMobileValue) {
      return {
        [SEARCH_TYPE.DEALS]: 'Search deals',
        [SEARCH_TYPE.REWARDS]: 'Search rewards',
        [SEARCH_TYPE.GIFT_CARDS]: 'Search gift cards',
      }[searchType];
    }
    return 'Search';
  }, [searchType]);
};

export default useSearchPlaceHolderText;
