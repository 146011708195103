const REWARD_ID_PARAM = 'rewardId';
const REWARD_ID_ROUTE_PARAM = `:${REWARD_ID_PARAM}`;
const STORE_ID_PARAM = 'storeId';
const STORE_ID_ROUTE_PARAM = `:${STORE_ID_PARAM}`;
const PROMO_ID_PARAM = 'promo';
const PROMO_ID_ROUTE_PARAM = `:${PROMO_ID_PARAM}`;
const BRAND_ID_PARAM = 'brandId';
const BRAND_ID_ROUTE_PARAM = `:${BRAND_ID_PARAM}`;
const GIFT_CARD_ID_PARAM = 'giftCardId';
const GIFT_CARD_ID_ROUTE_PARAM = `:${GIFT_CARD_ID_PARAM}`;
const WF_RETURN_URL_SEARCH_PARAM = 'wfreturnUrl';
const WF_DEVICE_ID_SEARCH_PARAM = 'wfedid';

const REGISTER_CODE_PARAM = 'code';
const REGISTER_SIGN_PARAM = 'sign';
const REGISTER_SIGNED_AT_PARAM = 'signed-at';
const REGISTER_PIN_PARAM = 'pin';
const REGISTER_OPTIN_PARAM = 'optin';
const REGISTER_SIGNUP_PARAM = 'signup';
const REGISTER_FROM_PARAM = 'from';
const REGISTER_DATA_PARAM = 'data';
const REGISTER_SOURCE_PARAM = 'source';
const REGISTER_TOPIC_PARAM = 'topic';

const TYPE_SEARCH_PARAM = 'loginType';
const EMAIL_SEARCH_PARAM = 'loginEmail';
const CATEGORY_SEARCH_PARAM = 'category';
const SORT_SEARCH_PARAM = 'sort';
const AUTH_TOKEN_SEARCH_PARAM = 'authToken';
const FILTER_SEARCH_PARAMS = {
  TYPE: 'type',
  MIN_VALUE: 'minValue',
  MAX_VALUE: 'maxValue',
  IS_NEW: 'isNew',
};
const TAB_SEARCH_PARAM = 'tab';

const ROUTE_HOME = '/';
const ROUTE_LOGIN = '/login';
const ROUTE_EMBED = '/embed';
const ROUTE_EMBED_LOGIN = `${ROUTE_EMBED}${ROUTE_LOGIN}`;
const ROUTE_LOGIN_WITH_LINK = '/login-with-link';
const ROUTE_LOGOUT = '/logout';
const ROUTE_SIGNUP = '/signup';
const ROUTE_SETTINGS = '/settings';
const ROUTE_ADDRESSES = '/addresses';
const ROUTE_DASHBOARD = '/earnings';
const ROUTE_DASHBOARD_REWARDS = '/rewards';
const ROUTE_REDEEM = '/redeem';
const ROUTE_REGISTER = '/register';
const ROUTE_MY_ACCOUNT = '/account';
const ROUTE_PROFILE = `${ROUTE_MY_ACCOUNT}/details`;
const ROUTE_WALLET = '/wallet';
const ROUTE_WALLET_REWARDS = `${ROUTE_WALLET}/rewards`;
const ROUTE_WALLET_GIFT_CARDS = `${ROUTE_WALLET}/gift-cards`;
const ROUTE_TAKE_BACK_BAGS = '/take-back-bags';
const ROUTE_TAKE_BACK_BAGS_DETAILS = `${ROUTE_TAKE_BACK_BAGS}/details`;
const ROUTE_REWARD = '/reward';
const ROUTE_REWARD_ID = `${ROUTE_REWARD}/${REWARD_ID_ROUTE_PARAM}`;
const ROUTE_REWARDS = '/trashiecash-rewards';
const ROUTE_REWARD_INFO = `${ROUTE_REWARDS}${ROUTE_REWARD}/${REWARD_ID_ROUTE_PARAM}`;
const ROUTE_STORE = '/store';
const ROUTE_REWARDS_STORE_ID = `${ROUTE_REWARDS}${ROUTE_STORE}/${STORE_ID_ROUTE_PARAM}`;
const ROUTE_REWARDS_STORE_REWARD_ID = `${ROUTE_REWARDS}${ROUTE_STORE}/${STORE_ID_ROUTE_PARAM}/${REWARD_ID_ROUTE_PARAM}`;
const ROUTE_DEALS = '/trashiecash-deals';
const ROUTE_DEAL_STORE_ID = `${ROUTE_DEALS}${ROUTE_STORE}/${STORE_ID_ROUTE_PARAM}`;
const ROUTE_DEAL_STORE_REWARD_ID = `${ROUTE_DEALS}${ROUTE_STORE}/${STORE_ID_ROUTE_PARAM}/${REWARD_ID_ROUTE_PARAM}`;
const ROUTE_DEAL = '/deal';
const ROUTE_DEAL_INFO = `${ROUTE_DEALS}${ROUTE_DEAL}/${REWARD_ID_ROUTE_PARAM}`;
const ROUTE_GIFT_CARDS = '/trashiecash-gift-cards';
const ROUTE_BRAND = '/brand';
const ROUTE_GIFT_CARDS_BRAND_ID = `${ROUTE_GIFT_CARDS}${ROUTE_BRAND}/${BRAND_ID_ROUTE_PARAM}`;
const ROUTE_GIFT_CARD = '/gift-card';
const ROUTE_GIFT_CARD_INFO = `${ROUTE_GIFT_CARDS}${ROUTE_GIFT_CARD}/${GIFT_CARD_ID_ROUTE_PARAM}`;

const ROUTE_REWARDS_OLD = '/rewards';
const ROUTE_REWARDS_OLD_STORE_ID = `${ROUTE_REWARDS_OLD}${ROUTE_STORE}/${STORE_ID_ROUTE_PARAM}`;
const ROUTE_REWARDS_OLD_STORE_REWARD_ID = `${ROUTE_REWARDS_OLD_STORE_ID}/${REWARD_ID_ROUTE_PARAM}`;
const ROUTE_REWARD_OLD_INFO = `${ROUTE_REWARDS_OLD}${ROUTE_REWARD}/${REWARD_ID_ROUTE_PARAM}`;

const ROUTE_TRANSACTIONS = '/transactions';
const ROUTE_OFFERS = '/earn-trashiecash';

const ANCHOR_BALANCE = 'balance';
const ANCHOR_REWARDS = 'rewards';
const ANCHOR_OFFERS = 'offers';
const ANCHOR_PRODUCTS = 'products';
const ANCHOR_WALLET = 'wallet';
const ANCHOR_MY_REWARDS = 'myrewards';
const ANCHOR_MY_TBB = 'mytbbb';
const ANCHOR_TRANSACTIONS = 'transactions';
const ANCHOR_DEALS = 'deals';
const ANCHOR_GIFT_CARDS = 'giftcards';

const ROUTE_BALANCE = `${ROUTE_DASHBOARD}#${ANCHOR_BALANCE}`;

const ROUTES_TREE = {
  children: {
    [ROUTE_LOGIN]: {
      title: 'Login',
    },
    [ROUTE_LOGOUT]: {
      title: 'Logout',
    },
    [ROUTE_DASHBOARD]: {
      title: 'Deals',
      children: {
        [ROUTE_DEALS]: {
          title: 'Instant Deals',
          children: {
            [ROUTE_DEAL_STORE_ID]: {
              title: 'Deal details',
            },
            [ROUTE_DEAL_STORE_REWARD_ID]: {
              title: 'Deal details',
            },
          },
        },
        [ROUTE_GIFT_CARDS]: {
          title: 'Gift Cards',
          children: {
            [ROUTE_GIFT_CARDS_BRAND_ID]: {
              title: 'Gift Card details',
            },
          },
        },
        [ROUTE_OFFERS]: {
          title: 'Earn TrashieCash™',
        },
      },
    },
    [ROUTE_DASHBOARD_REWARDS]: {
      title: 'Rewards',
      children: {
        [ROUTE_REWARDS]: {
          title: 'Premium Rewards',
          children: {
            [ROUTE_REWARDS_STORE_ID]: {
              title: 'Reward details',
            },
            [ROUTE_REWARDS_STORE_REWARD_ID]: {
              title: 'Reward details',
            },
          },
        },
      },
    },
    [ROUTE_MY_ACCOUNT]: {
      title: 'Account',
      children: {
        [ROUTE_PROFILE]: {
          title: 'Account details',
          children: {
            [ROUTE_SETTINGS]: {
              title: 'Change Password',
            },
          },
        },
        [ROUTE_TRANSACTIONS]: {
          title: 'Transactions',
        },
      },
    },
    [ROUTE_WALLET]: {
      title: 'Wallet',
      children: {
        [ROUTE_DEAL_INFO]: {
          title: 'Deal details',
        },
        [ROUTE_WALLET_REWARDS]: {
          title: 'Premium Rewards',
          children: {
            [ROUTE_REWARD_INFO]: {
              title: 'Reward details',
            },
          },
        },
        [ROUTE_WALLET_GIFT_CARDS]: {
          title: 'Gift Cards',
          children: {
            [ROUTE_GIFT_CARD_INFO]: {
              title: 'Gift Card details',
            },
          },
        },
      },
    },
    [ROUTE_TAKE_BACK_BAGS]: {
      title: 'Recycle',
      children: {
        [ROUTE_TAKE_BACK_BAGS_DETAILS]: {
          title: 'Label details',
        },
      },
    },
    [ROUTE_REWARD_ID]: {
      title: 'Reward',
    },
    [ROUTE_REGISTER]: {
      title: 'Register',
    },
  },
};

export {
  // Params
  REWARD_ID_PARAM,
  REWARD_ID_ROUTE_PARAM,
  STORE_ID_PARAM,
  STORE_ID_ROUTE_PARAM,
  PROMO_ID_PARAM,
  PROMO_ID_ROUTE_PARAM,
  TYPE_SEARCH_PARAM,
  EMAIL_SEARCH_PARAM,
  CATEGORY_SEARCH_PARAM,
  SORT_SEARCH_PARAM,
  FILTER_SEARCH_PARAMS,
  TAB_SEARCH_PARAM,
  WF_RETURN_URL_SEARCH_PARAM,
  WF_DEVICE_ID_SEARCH_PARAM,
  REGISTER_CODE_PARAM,
  REGISTER_SIGN_PARAM,
  REGISTER_SIGNED_AT_PARAM,
  REGISTER_PIN_PARAM,
  REGISTER_OPTIN_PARAM,
  REGISTER_SIGNUP_PARAM,
  REGISTER_FROM_PARAM,
  REGISTER_DATA_PARAM,
  REGISTER_SOURCE_PARAM,
  REGISTER_TOPIC_PARAM,
  AUTH_TOKEN_SEARCH_PARAM,
  BRAND_ID_PARAM,
  BRAND_ID_ROUTE_PARAM,
  GIFT_CARD_ID_PARAM,
  GIFT_CARD_ID_ROUTE_PARAM,
  // Routes
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_EMBED,
  ROUTE_EMBED_LOGIN,
  ROUTE_LOGIN_WITH_LINK,
  ROUTE_LOGOUT,
  ROUTE_SIGNUP,
  ROUTE_SETTINGS,
  ROUTE_PROFILE,
  ROUTE_ADDRESSES,
  ROUTE_DASHBOARD,
  ROUTE_REDEEM,
  ROUTE_REGISTER,
  ROUTE_BALANCE,
  ROUTE_DASHBOARD_REWARDS,
  ROUTE_OFFERS,
  ROUTE_TRANSACTIONS,
  ROUTE_WALLET,
  ROUTE_WALLET_REWARDS,
  ROUTE_WALLET_GIFT_CARDS,
  ROUTE_TAKE_BACK_BAGS,
  ROUTE_TAKE_BACK_BAGS_DETAILS,
  ROUTE_MY_ACCOUNT,
  ROUTE_REWARDS,
  ROUTE_REWARD,
  ROUTE_REWARD_ID,
  ROUTE_REWARDS_STORE_ID,
  ROUTE_REWARDS_STORE_REWARD_ID,
  ROUTE_REWARD_INFO,
  ROUTE_STORE,
  ROUTE_REWARDS_OLD,
  ROUTE_REWARDS_OLD_STORE_ID,
  ROUTE_REWARDS_OLD_STORE_REWARD_ID,
  ROUTE_REWARD_OLD_INFO,
  ROUTE_DEALS,
  ROUTE_DEAL_STORE_ID,
  ROUTE_DEAL_STORE_REWARD_ID,
  ROUTE_DEAL_INFO,
  ROUTE_GIFT_CARDS,
  ROUTE_GIFT_CARDS_BRAND_ID,
  ROUTE_GIFT_CARD_INFO,
  ROUTES_TREE,
  // Anchors
  ANCHOR_BALANCE,
  ANCHOR_OFFERS,
  ANCHOR_REWARDS,
  ANCHOR_PRODUCTS,
  ANCHOR_WALLET,
  ANCHOR_MY_REWARDS,
  ANCHOR_MY_TBB,
  ANCHOR_TRANSACTIONS,
  ANCHOR_DEALS,
  ANCHOR_GIFT_CARDS,
};
