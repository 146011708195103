import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Box, Input } from '@mui/material';
import RewardsResults from '../RewardsResults';
import closeIcon from '../../../images/close-icon.svg';
import './RewardsSearch.scss';

import ArrowCircledIcon from '../../../images/arrow-circled-icon.svg';

const WORD_SEARCH_MIN_LENGTH = 3;
const SEARCH_DEBOUNCE_TIME = 700;

const RewardsSearch = ({
  searchType,
  loadCategoryRewards,
  categoryRewards,
  categoryRewardsTotal,
  handleOnClick,
  rewardProperties,
  isLoadingRewards,
  isLoadingConfig,
  rewardsError,
  listElementId,
  searchedText,
  setSearchedText,
  onClose,
  onClear,
}) => {
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const debouncedSearch = useCallback(
    debounce(async (newTextSearch) => {
      loadCategoryRewards('', '', '', [], '');
      if (newTextSearch && newTextSearch.length >= WORD_SEARCH_MIN_LENGTH) {
        await loadCategoryRewards('', '', '', [], newTextSearch);
        setFirstLoad(false);
      } else if (!newTextSearch) {
        setFirstLoad(true);
      }
    }, SEARCH_DEBOUNCE_TIME),
    [],
  );

  const handleChange = useCallback((event) => {
    const newTextSearch = event.target.value;
    setSearchedText(newTextSearch);
    debouncedSearch(newTextSearch);
  }, [debouncedSearch, setSearchedText]);

  const handleLeaveSearch = useCallback(() => {
    setFirstLoad(true);
    setSearchedText('');
    onClear?.();
  }, [onClear, setFirstLoad, setSearchedText]);

  const handleOnClose = useCallback(() => {
    setSearchedText('');
    onClose();
  }, [onClose, setSearchedText]);

  return (
    <div className="RewardsSearch">
      <Box className="RewardsSearch__content">
        <Box className="RewardsSearch__content-header">
          <button
            className="RewardsSearch__content-header-back"
            aria-label="back"
            type="button"
            onClick={handleOnClose}
          >
            <img src={ArrowCircledIcon} alt="arrow-back" />
          </button>
          <Box className="RewardsSearch__content-input">
            <Input
              className="RewardsSearch__input"
              value={searchedText}
              placeholder="Search by brand name"
              onChange={handleChange}
              disableUnderline
              inputRef={input => input?.focus()}
            />
            {searchedText &&
              (
              <Box
                onClick={handleLeaveSearch}
              >
                <img
                  className="RewardsSearch__close-icon"
                  src={closeIcon}
                  alt="close-icon"
                />
              </Box>
              )}
          </Box>
        </Box>
        <Box className="RewardsSearch__content-results">
          <RewardsResults
            searchType={searchType}
            listElementId={listElementId}
            isLoadingRewards={isLoadingRewards}
            isLoadingConfig={isLoadingConfig}
            categoryRewards={categoryRewards}
            rewardProperties={rewardProperties}
            loadCategoryRewards={loadCategoryRewards}
            selectedCategory=""
            sortingSelected={{}}
            filterSelected={{}}
            categoryRewardsTotal={categoryRewardsTotal}
            handleOnClick={handleOnClick}
            rewardsError={rewardsError}
            firstLoad={firstLoad}
            isTextSearch
            searchedText={searchedText}
          />
        </Box>
      </Box>
    </div>

  );
};

export default RewardsSearch;
