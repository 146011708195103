import classNames from 'classnames';
import LoadingBar from '../LoadingBar';
import { isMobile } from '../../../../hooks/useBreakPoint';
import ButtonBack from '../ButtonBack/ButtonBack';
import ListHeaders from '../ListHeaders';

import './PageContent.scss';

const PageContent = ({
  title = '',
  subtitle = '',
  children,
  cta,
  isLoading,
  error = '',
  className = '',
  parentUrl = '',
  backButtonText = 'GO BACK',
}) => {
  const mobile = isMobile();
  return (
    <div className={classNames('PageContent', className)}>
      <div className="PageContent__container">
        {cta && (
          <div className="PageContent__cta">
            {cta}
          </div>
        )}

        {!mobile && (
          <div className="PageContent__header">
            <ButtonBack className="Profile__back-button" route={parentUrl}>
              {backButtonText}
            </ButtonBack>
            <ListHeaders titleSectionClassName="Profile__list-headers" title={title} subtitle={subtitle} />
          </div>
        )}
        <div className="PageContent__content">
          {error ? (
            <h2 className="PageContent__content--error">{error}</h2>
          ) : (
            (isLoading && (
              <LoadingBar className="PageContent__content--loader" />
            )) || children
          )}
        </div>
      </div>
    </div>
  );
};

export default PageContent;
