import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_DASHBOARD, ROUTE_PROFILE } from '../../config/routes';
import Button, { BUTTON_TYPE } from '../../global/Button/Button';
import Input from '../../global/InputNew';
import { getGenericError } from '../../utils/errors';

import Loader from '../../../global/Loader';
import ListHeaders from '../../global/ListHeaders';
import ButtonBack from '../../global/ButtonBack/ButtonBack';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { isMobile } from '../../../../hooks/useBreakPoint';

import './Settings.scss';

const backButtonText = 'GO TO MY ACCOUNT DETAILS';
const changePasswordTitle = 'Change Password';

const Settings = () => {
  const history = useHistory();
  const mobile = isMobile();
  const { isSignedIn, isLoading: isLoadingFirebaseAuth, user } = useFirebaseAuth();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFinished, setIsFinished] = useState(false);

  const handleResetPassword = useCallback(async () => {
    if (!password || !confirmPassword) {
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      setErrorMessage('');
      setIsLoading(true);

      await user.updatePassword(password);

      setPassword('');
      setConfirmPassword('');
      setIsFinished(true);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(error?.message || getGenericError());
      setIsLoading(false);
    }
  }, [password, confirmPassword]);

  const handleOnPasswordChange = useCallback((newValue) => {
    setErrorMessage('');
    setPassword(newValue);
  }, []);

  const handleOnConfirmPasswordChange = useCallback((newValue) => {
    setErrorMessage('');
    setConfirmPassword(newValue);
  }, []);

  const handleClear = useCallback(() => {
    setErrorMessage('');
    setPassword('');
    setConfirmPassword('');
    setIsFinished(false);
    setIsLoading(false);

    history.push(ROUTE_PROFILE);
  }, []);

  useEffect(() => {
    if (isSignedIn && user) {
      setIsLoading(false);
    }
  }, [isSignedIn, user]);

  if (isLoading || isLoadingFirebaseAuth) {
    return (
      <Loader className="Settings__loader" />
    );
  }

  return (
    <div className="Settings">
      <div className="Settings__content">
        <div className="Settings__header">
          {!mobile && (
            <>
              <ButtonBack className="Settings__back-button" route={ROUTE_PROFILE}>
                {backButtonText}
              </ButtonBack>
              {!isFinished && <ListHeaders title={changePasswordTitle} />}
            </>
          )}
        </div>
        <div className="Settings__container">
          <div className="Settings__form">
            {isFinished ? (
              <>
                <p className="Settings__intro">Your Password has been changed!</p>
                <Button
                  type={BUTTON_TYPE.QUATERNARY}
                  className="Settings__cta"
                  onClick={() => history.push(ROUTE_DASHBOARD)}
                >
                  Go to Deals
                </Button>
                <div className="Settings__options">
                  <button
                    type="button"
                    className="Settings__option"
                    onClick={handleClear}
                  >
                    Go to My Account Details
                  </button>
                </div>
              </>
            ) : (
              <>
                <Input
                  type="password"
                  id="password"
                  label="Enter New Password"
                  containerClassName="Settings__form-item"
                  value={password}
                  onChange={handleOnPasswordChange}
                  errorMessage={Boolean(errorMessage)}
                />
                <Input
                  type="password"
                  id="confirmPassword"
                  label="Re-enter New Password"
                  containerClassName="Settings__form-item"
                  value={confirmPassword}
                  onChange={handleOnConfirmPasswordChange}
                  errorMessage={errorMessage}
                />
                <Button
                  type={BUTTON_TYPE.QUATERNARY}
                  onClick={handleResetPassword}
                  disabled={!password || !confirmPassword || errorMessage}
                >
                  UPDATE
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
