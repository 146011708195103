const POSTHOG_DATA_ATTRIBUTE = 'data-ph-capture-attribute-';

const POSTHOG_PROPERTIES = {
  TRACKER_ID: 'tracker-id',
  ACTION_ID: 'action-id',
  MODIFIER_ID: 'modifier-id',
  REWARD_ID: 'reward-id',
  REWARD_NAME: 'reward-name',
  GIFT_CARD_ID: 'gift-card-id',
  GIFT_CARD_NAME: 'gift-card-name',
  OFFER_MERCHANT_ID: 'offer-merchant-id',
  OFFER_MERCHANT_NAME: 'offer-merchant-name',
  METHOD: 'method',
  SUBSCRIBE: 'subscribe',
  SOURCE: 'source',
  TBB_STEP: 'tbb-step',
  TBB_SHIPPING_PROVIDER: 'tbb-shipping-provider',
  TBB_RECYCLING_PRODUCT_TYPE: 'tbb-recycling-product-type',
  GIFT_CARDS_SEGMENT: 'gift-cards-segment',
  SECTION: 'section',
};

const POSTHOG_CAPTURE_ATTRIBUTES = {
  TRACKER_ID: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.TRACKER_ID}`,
  ACTION_ID: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.ACTION_ID}`,
  MODIFIER_ID: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.MODIFIER_ID}`,
  REWARD_ID: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.REWARD_ID}`,
  REWARD_NAME: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.REWARD_NAME}`,
  GIFT_CARD_ID: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.GIFT_CARD_ID}`,
  GIFT_CARD_NAME: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.GIFT_CARD_NAME}`,
  OFFER_MERCHANT_ID: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.OFFER_MERCHANT_ID}`,
  OFFER_MERCHANT_NAME: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.OFFER_MERCHANT_NAME}`,
  TBB_STEP: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.TBB_STEP}`,
  TBB_RECYCLING_PRODUCT_TYPE: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.TBB_RECYCLING_PRODUCT_TYPE}`,
  SECTION: `${POSTHOG_DATA_ATTRIBUTE}${POSTHOG_PROPERTIES.SECTION}`,
};

const TRACKER_EVENT_TYPES = {
  CLICKED_REWARD: 'Clicked Reward',
  CLICKED_GIFT_CARD: 'Clicked Gift Card',
  CLICKED_GIFT_CARD_LINK: 'Clicked Gift Card Link',
  CLICKED_AFFILIATE_LINK: 'Clicked Affiliate Link',
  REDEEMED_REWARD: 'Redeemed Reward',
  CLAIMED_GIFT_CARD: 'Claimed Gift Card',
  LOGIN: 'user_logged_in',
  SIGNUP: 'user_signed_up',
  GIFT_CARDS_SEGMENT_SET: 'gift_cards_segment_set',
};

const TRACKER_ACTION_TYPES = {
  REWARD_ITEM: 'reward-item',
  GIFT_CARD_ITEM: 'gift-card-item',
  GIFT_CARD_LINK: 'gift-card-link',
  AFFILIATE_LINK: 'affiliate-link',
  CONFIRM_BUTTON: 'confirm-button',
  OFFER_ITEM: 'offer-item',
};

const MODIFIERS = {
  WF_LINK: 'wf-link',
};

function setTrackerData() {
  // eslint-disable-next-line no-restricted-syntax
  for (const i in this) {
    if (typeof this[i] === 'object') {
      this[i].init = this.init;
      this[i].init();
      this[i].parent = this;
      this[i].trackerKey = i;
    }
  }

  return this;
}

const TRACKER_IDS = {
  init: setTrackerData,
  login: {
    emailLinkButton: {
      selectable: true,
    },
    passwordButton: {
      selectable: true,
    },
    googleButton: {
      selectable: true,
    },
  },
  dashboard: {
    hero: {
      trashiecashRewards: {
        selectable: false,
      },
      earnTrashiecash: {
        selectable: false,
      },
      shopTakeBackBag: {
        selectable: false,
      },
      shippingLabels: {
        selectable: false,
      },
      instantDeals: {
        selectable: false,
      },
    },
    marketing: {
      slide: {
        selectable: true,
      },
    },
    instantDeals: {
      viewAllButton: {
        selectable: true,
      },
    },
    trashiecashRewards: {
      viewAllButton: {
        selectable: true,
      },
    },
    earnTrashiecash: {
      viewAllButton: {
        selectable: true,
      },
    },
  },
  rewards: {
    list: {
      rewardItem: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_REWARD,
        action: TRACKER_ACTION_TYPES.REWARD_ITEM,
      },
      claimButton: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.REDEEMED_REWARD,
        action: TRACKER_ACTION_TYPES.REWARD_ITEM,
      },
    },
    marketing: {
      slide: {
        selectable: true,
      },
    },
    singleMerchantPage: {
      rewardItem: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_REWARD,
        action: TRACKER_ACTION_TYPES.REWARD_ITEM,
      },
      confirmButton: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.REDEEMED_REWARD,
        action: TRACKER_ACTION_TYPES.CONFIRM_BUTTON,
      },
    },
  },
  giftCards: {
    list: {
      giftCardItem: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_GIFT_CARD,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_ITEM,
      },
    },
    brandPage: {
      confirmButton: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLAIMED_GIFT_CARD,
        action: TRACKER_ACTION_TYPES.CONFIRM_BUTTON,
      },
    },
    detail: {
      imageLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
      uniqueLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
      codeLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
      copyCodeLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
      copyPinLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
      shopButton: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
    },
  },
  myRewards: {
    list: {
      cardLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      titleLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      claimLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      copyCodeLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      copyPinLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      shopButton: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
    },
    detail: {
      imageLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      cardLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      uniqueLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      codeLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      pinLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      claimLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      copyCodeLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      copyPinLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
      shopButton: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
    },
    terms: {
      imageLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.AFFILIATE_LINK,
      },
    },
  },
  myGiftCards: {
    list: {
      cardLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_GIFT_CARD_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
      titleLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_GIFT_CARD_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
      codeLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_AFFILIATE_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
      copyCodeLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_GIFT_CARD_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
      copyPinLink: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_GIFT_CARD_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
      shopButton: {
        selectable: true,
        event: TRACKER_EVENT_TYPES.CLICKED_GIFT_CARD_LINK,
        action: TRACKER_ACTION_TYPES.GIFT_CARD_LINK,
      },
    },
  },
  offers: {
    offerItem: {
      selectable: true,
      action: TRACKER_ACTION_TYPES.OFFER_ITEM,
    },
  },
  tbb: {
    stepButton: {
      selectable: true,
    },
  },
}.init();

const getTrackerKeys = (trackerKeys = [], tracker = {}) => {
  if (tracker.parent) {
    return getTrackerKeys([...trackerKeys, tracker.trackerKey], tracker.parent);
  }

  return trackerKeys;
};

const getTrackerId = (tracker = {}) => {
  if (!tracker.selectable) {
    return '';
  }

  const trackerKeys = getTrackerKeys([], tracker);

  return trackerKeys.reverse().join('.');
};

const getEventName = (tracker = {}) => {
  if (!tracker.selectable) {
    return '';
  }

  return tracker.event;
};

const getActionType = (tracker = {}) => {
  if (!tracker.selectable) {
    return '';
  }

  return tracker.action;
};

const getConditionalModifier = (attribute, modifier = '', condition = false) => (condition ? { [attribute]: modifier } : {});

export {
  POSTHOG_PROPERTIES,
  TRACKER_IDS,
  TRACKER_EVENT_TYPES,
  POSTHOG_CAPTURE_ATTRIBUTES,
  MODIFIERS,
  getTrackerId,
  getEventName,
  getActionType,
  getConditionalModifier,
};
