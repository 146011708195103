import './Dashboard.scss';

const Dashboard = ({ children }) => (
  <div className="Dashboard">
    <div className="Dashboard__container">
      {children}
    </div>
  </div>
);

export default Dashboard;
