import classNames from 'classnames';
import { Box } from '@mui/material';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../Button';
import './StatusTabs.scss';

const StatusTabs = ({ tabArray, onClickAction, status }) => (
  <Box className="StatusTabs">
    {tabArray.map(({ type }) => (
      <Button
        key={type}
        className={classNames('StatusTabs__button', { 'StatusTabs__button--active': status === type })}
        type={BUTTON_TYPE.SENARY}
        size={BUTTON_SIZE.MEDIUM_WITHOUT_BORDER}
        onClick={() => onClickAction(type)}
      >
        {type}
      </Button>
    ))}
  </Box>
);

export default StatusTabs;
