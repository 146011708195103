import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import ClockIcon from '../../../images/clock-icon.svg';
import TCCoin from '../../../images/TC-icon.svg';
import WandSparklesIcon from '../../../images/wand-sparkles.svg';
import CheckCircleIconBold from '../../../images/check-circle-icon-bold.webp';
import IconUndo from '../../../images/icon-undo.webp';

import CopyButton from '../../CopyButton';
import Card, { CARD_TYPE } from '../../Rewards/Card';
import MyRewardItemModal from '../../MyRewardItemModal';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../Button';

import { ROUTE_REWARD_INFO, ROUTE_DEAL_INFO, REWARD_ID_ROUTE_PARAM } from '../../../config/routes';
import { getActionType, getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import { isMobile } from '../../../../../hooks/useBreakPoint';
import { patchRewardCodeIsUsed } from '../../../utils/service';
import { isUsed, isExpired } from '../../../utils/utils';
import { isPastDate, formatMMDDYY, formatExpirationDate, isSoonDate } from '../../../utils/dates';
import { getRewardCode, getUniqueLink, isDonationType } from '../../../utils/rewards';

import './MyRewardItem.scss';

const {
  myRewards: {
    list: {
      titleLink: trackerTitleLink,
      copyCodeLink: trackerCopyCodeLink,
      copyPinLink: trackerCopyPinLink,
      shopButton: trackerShopButton,
    },
  },
} = TRACKER_IDS;

const COPY_DATA_TYPES = {
  CODE: 'code',
  PIN: 'pin',
};

const SHOP_EARN_TEXT = 'SHOP & EARN';
const MARK_AS_USED_TEXT = 'MARK AS USED';
const UNMARK_USED_TEXT = 'MARK AS NOT USED';

const MyRewardItem = ({
  rewardItem,
  isCopied,
  onCopy,
  status,
  onClickIsUsed,
  showIsUsed = true,
}) => {
  const timeoutRef = useRef(null);
  const isMobileSize = isMobile();
  const [copiedData, setCopiedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const {
    uid,
    redeemedAt,
    expireAt,
    pin,
    redeemUrl,
    reward: {
      description,
      type,
      exchangeValue,
      store: {
        title: storeTitle,
        logoUrl,
        headerLogoProperties,
        cardBgColor,
        tcCreditAmount,
      },
    },
  } = useMemo(() => rewardItem, [rewardItem]);

  const isDonation = isDonationType(type);
  const isTcCredit = useMemo(() => {
    const tcCreditAmountValue = Number.parseInt(tcCreditAmount, 10);
    return (typeof tcCreditAmountValue === 'number' && tcCreditAmountValue > 0);
  }, [tcCreditAmount]);
  const usedStatusReward = isUsed(status);
  const expiredStatusReward = isExpired(status);

  const isDeal = useMemo(() => exchangeValue === '0', [exchangeValue]);

  const toggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const toggleIsLoading = useCallback(() => {
    setisLoading((prev) => !prev);
  }, []);

  const toggleIsError = useCallback((isErrorValue) => {
    setIsError(isErrorValue);
  }, [isError]);

  const rewardCode = useMemo(() => (
    getRewardCode({ ...rewardItem, type })
  ), [rewardItem]);

  const uniqueLink = useMemo(() => (
    getUniqueLink(rewardItem.reward)
  ), [rewardItem]);

  const setRewardCodesIsUsed = useCallback(async (rewardId, isUsedType) => {
    toggleIsLoading();
    const { error } = await patchRewardCodeIsUsed({
      rewardId,
      isUsed: isUsedType,
    });

    if (error) {
      toggleIsError(true);
    } else {
      onClickIsUsed();
      toggleIsError(false);
      toggleModal();
    }
    toggleIsLoading();
  });

  const handleCopy = useCallback((dataType) => {
    const data = dataType === COPY_DATA_TYPES.PIN ? pin : rewardCode;
    onCopy(uid);

    navigator.clipboard.writeText(data);

    timeoutRef.current = setTimeout(() => {
      window.location.href = uniqueLink;
    }, 500);
  }, [rewardCode, pin, uniqueLink]);

  useEffect(() => {
    if (!isCopied) {
      setCopiedData(null);
    }
  }, [isCopied]);

  useEffect(() => (
    () => timeoutRef?.current && clearTimeout(timeoutRef.current)
  ), []);

  const linkText = useCallback(() => `${isDonation ? 'DONATION' : (isDeal && 'DEAL') || 'REWARD'} DETAILS`);

  return (
    <div
      role="listitem"
      className="MyRewardItem"
    >
      <div className="MyRewardItem__card">
        <Card
          logoUrl={logoUrl}
          logoStyle={headerLogoProperties}
          backgroundColor={cardBgColor}
          type={CARD_TYPE.DEFAULT}
          cardLink={uniqueLink}
        />
      </div>
      {isMobileSize && (
        <div className="MyRewardItem__content content-mobile">
          <a
            className="MyRewardItem__content--title"
            href={uniqueLink}
            {...{
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerTitleLink),
              [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerTitleLink),
            }}
          >
            {storeTitle}
          </a>
          <div className="MyRewardItem__content--details">
            <span className="details-small">{description}</span>
          </div>
        </div>
      )}
      <div className={classNames('MyRewardItem__content', { expired: expireAt && isPastDate(expireAt) })}>
        {!isMobileSize && (
          <a
            className="MyRewardItem__content--title"
            href={uniqueLink}
            {...{
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerTitleLink),
              [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerTitleLink),
            }}
          >
            {storeTitle}
          </a>
        )}
        {(!isDonation && expireAt && !isPastDate(expireAt)) && (
          <div className="MyRewardItem__content--expiration">
            <img src={ClockIcon} alt="clock icon" />
            {isSoonDate(expireAt, 7) && <span>{formatExpirationDate({ date: expireAt })}</span>}
          </div>
        )}
        {!isMobileSize && (
          <div className="MyRewardItem__content--details">
            <span className="details-small">{description}</span>
          </div>
        )}
        {!isDonation && (
          <div className="MyRewardItem__content--details details-highlight">
            <span className="details-label">
              {rewardCode ? 'CODE:' : `MY ${isDeal ? 'DEAL' : 'REWARD'}`}
            </span>
            <CopyButton
              className="details-highlight"
              data={rewardCode || (
                <a href={redeemUrl}>CLICK TO CLAIM</a>
              )}
              onClick={rewardCode ? () => handleCopy(COPY_DATA_TYPES.CODE) : undefined}
              copied={copiedData === COPY_DATA_TYPES.CODE}
              trackerProps={{
                [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCopyCodeLink),
                [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCopyCodeLink),
              }}
            />
          </div>
        )}
        {pin && (
          <div className="MyRewardItem__content--details details-highlight">
            <span className="details-label">PIN:</span>
            <CopyButton
              className="details-highlight"
              data={pin}
              onClick={() => handleCopy(COPY_DATA_TYPES.PIN)}
              copied={copiedData === COPY_DATA_TYPES.PIN}
              trackerProps={{
                [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerCopyPinLink),
                [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerCopyPinLink),
              }}
            />
          </div>
        )}
        <div className="MyRewardItem__content--details">
          <span className="details-label details-small">Purchased:</span>
          <span className="details-small">{formatMMDDYY(redeemedAt)}</span>
          {!isDonation && expireAt && (
            <div className={classNames('MyRewardItem__expired', { 'contentDetails--red': expiredStatusReward })}>
              <span className="details-label details-small">
                {`${expiredStatusReward ? 'Expired' : 'Expires'}:`}
              </span>
              <span className="details-small">{formatMMDDYY(expireAt)}</span>
            </div>
          )}
        </div>
        <div className="MyRewardItem__contentLinks">
          <Link
            to={`${isDeal ? ROUTE_DEAL_INFO : ROUTE_REWARD_INFO}`.replace(REWARD_ID_ROUTE_PARAM, uid)}
            className="MyRewardItem__content--link"
          >
            {isDonation ? '' : (<img src={WandSparklesIcon} alt="icon" className="MyRewardItem__iconLink" />)}
            {linkText()}
          </Link>
          {!isDonation && showIsUsed && (
            <Button
              className="MyRewardItem__content--link"
              type={BUTTON_TYPE.SENARY}
              size={BUTTON_SIZE.MEDIUM_WITHOUT_BORDER}
              onClick={() => toggleModal()}
            >
              <img src={usedStatusReward ? IconUndo : CheckCircleIconBold} alt="icon" className="MyRewardItem__iconLink" />
              {usedStatusReward ? UNMARK_USED_TEXT : MARK_AS_USED_TEXT}
            </Button>

          )}
        </div>
      </div>
      <a
        className="MyRewardItem__cta"
        href={uniqueLink}
        {...{
          [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerShopButton),
          [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerShopButton),
        }}
      >
        {isDonation && `GO TO ${storeTitle}`}
        {!isDonation && !isTcCredit && 'SHOP NOW'}
        {!isDonation && isTcCredit &&
          (
            <div className="MyRewardItem__TcCredit">
              <p className="MyRewardItem__label">{`${SHOP_EARN_TEXT} ${tcCreditAmount}`}</p>
              <img src={TCCoin} alt="icon" className="MyRewardItem__icon" />
            </div>
          )}
      </a>
      <MyRewardItemModal
        toggleModal={toggleModal}
        showModal={showModal}
        description={description}
        isError={isError}
        isLoading={isLoading}
        usedStatusReward={usedStatusReward}
        card={{ logoUrl, headerLogoProperties, cardBgColor, uniqueLink }}
        onClick={() => setRewardCodesIsUsed(uid, !usedStatusReward)}
      />
    </div>
  );
};

export default MyRewardItem;
