import React from 'react';

import classNames from 'classnames';

import styles from './Button.module.scss';

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary',
  QUINARY: 'quinary',
  SENARY: 'senary',
  LINK: 'link',
  LINK_QUATERNARY: 'link-quaternary',
  LINK_QUINARY: 'link-quinary',
  DISABLED: 'disabled',
};

export const BUTTON_SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  MEDIUM_WITHOUT_BORDER: 'medium-without-border',
  SMALL: 'small',
};

const Button = ({
  onClick,
  type = BUTTON_TYPE.PRIMARY,
  size = BUTTON_SIZE.LARGE,
  onDoubleClick,
  onMouseOver,
  children,
  disabled = false,
  // pre and post are intended to receive any jsx element to enhance the component
  prebutton,
  postbutton,
  style,
  className,
  onFocus,
  trackerProps = {},
}) => (
  <>
    {prebutton}
    <button
      className={classNames(
        styles[size],
        styles[type],
        { [styles[BUTTON_TYPE.DISABLED]]: disabled },
        className,
      )}
      onClick={onClick}
      disabled={disabled}
      onDoubleClick={onDoubleClick}
      onMouseOver={onMouseOver}
      onFocus={onFocus}
      style={style}
      type="button"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...trackerProps}
    >
      {children}
    </button>
    {postbutton}
  </>
);

export default Button;
