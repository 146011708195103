/* eslint-disable no-unused-vars */
import { isTrashieApp } from '../../../config/config';
import { FORDAYS_DOCUMENT_TITLE, FORDAYS_FAVICON, TRASHIE_DOCUMENT_TITLE } from '../config/config';
import { getCurrentRouteTitle } from './routes';
import { DEALS_AND_REWARDS_TYPES, WALLET_TABS } from '../config/wallet';

const equalStatus = (status) => (statusType) => (status === statusType);

const isActive = (status) => equalStatus(DEALS_AND_REWARDS_TYPES.ACTIVE)(status);

const isUsed = (status) => equalStatus(DEALS_AND_REWARDS_TYPES.USED)(status);

const isExpired = (status) => equalStatus(DEALS_AND_REWARDS_TYPES.EXPIRED)(status);

const isDeal = (tab) => equalStatus(WALLET_TABS.DEALS)(tab);

const isReward = (tab) => equalStatus(WALLET_TABS.REWARDS)(tab);

const setActiveTitle =
(
  statusValue,
  { ACTIVE_EMPTY_SATE, USED_EMPTY_SATE, EXPIRED_EMPTY_SATE },
) => {
  if (isActive(statusValue)) { return ACTIVE_EMPTY_SATE; }
  if (isUsed(statusValue)) { return USED_EMPTY_SATE; }
  return EXPIRED_EMPTY_SATE;
};

const setDocumentTitle = (page) => {
  let title = isTrashieApp() ? TRASHIE_DOCUMENT_TITLE : FORDAYS_DOCUMENT_TITLE;
  const routeTitle = getCurrentRouteTitle();

  if (page || routeTitle) {
    title = `${title} - ${page || routeTitle}`;
  }

  document.title = title;
};

const setDocumentFavicon = () => {
  if (!isTrashieApp()) {
    const link = document.querySelector('link[rel="icon"]');

    if (link) {
      link.setAttribute('href', FORDAYS_FAVICON);
    }
  }
};

const getUserName = (user, emailDefault = true) => {
  const { firstName, lastName, displayName, email } = user;

  let userName = '';

  if (firstName && lastName) {
    userName = `${firstName} ${lastName}`;
  } else if (displayName) {
    userName = displayName;
  } else if (emailDefault && email) {
    [userName] = email.split('@');
  }

  return userName;
};

const getUUID = (user) => {
  const { affiliate_cid: affiliateCID } = user;

  return affiliateCID.replaceAll('-', '');
};

const isNullOrEmpty = (value) => value === null || value?.trim().length === 0;

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

function capitalizeWords(str) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export {
  setDocumentTitle,
  setDocumentFavicon,
  getUserName,
  getUUID,
  isNullOrEmpty,
  isJson,
  capitalizeWords,
  isActive,
  isExpired,
  isUsed,
  isDeal,
  isReward,
  setActiveTitle,
};
