import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import OffersList from '../OffersList';
import DashboardSection from '../../../global/DashboardSection';
import { getGenericError } from '../../../utils/errors';
import { getOffers } from '../../../utils/service';
import {
  ANCHOR_OFFERS,
  ROUTE_OFFERS,
} from '../../../config/routes';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';

const PREVIEW_PAGE_SIZE = 9;

const {
  dashboard: {
    earnTrashiecash: {
      viewAllButton: trackerViewAllButton,
    },
  },
} = TRACKER_IDS;

const OffersPreview = () => {
  const history = useHistory();

  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const loadOffers = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: offersError,
    } = await getOffers();

    if (offersError) {
      setListData([]);
      setError(offersError.message ?? getGenericError());
      setIsLoading(false);
      return;
    }

    setError('');
    setListData(data.splice(0, PREVIEW_PAGE_SIZE));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadOffers();
  }, []);

  return (
    <DashboardSection
      anchor={ANCHOR_OFFERS}
      title="Earn TrashieCash™ while shopping"
      subTitle="Maximize your rewards with every purchase."
      onClick={() => history.push(ROUTE_OFFERS)}
      isLoading={isLoading}
      error={error}
      noData={listData.length ? undefined : 'No offers available at the moment'}
      ctaTrackerProps={{
        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerViewAllButton),
      }}
    >
      <OffersList offersList={listData} isDashBoard style={{ padding: '0 16px 16px' }} />
    </DashboardSection>

  );
};

export default OffersPreview;
