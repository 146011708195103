import moment from 'moment';

const formatMonthShort = (date) => moment(date).format('MMM');

const formatTransactionDate = (date) => moment(date).format('MMM D, YYYY');

const formatFullLocalDate = (date) => moment(date).local().format('lll (UTCZZ)');

const formatMMDDYY = (date) => moment(date).format('MM/DD/YY');

const isPastDate = (date) => moment(date).isBefore(moment());

const isSoonDate = (date, daysClose, stringUnit = 'days') => moment(date).subtract(Number(daysClose), stringUnit).isBefore(moment());

const timeDifference = (timeDuration) => {
  let expiration = '';

  switch (true) {
    case timeDuration.months() >= 12: {
      const duration = timeDuration.years();
      expiration = `${duration} ${duration === 1 ? 'year' : 'years'}`;
      break;
    }
    case timeDuration.months() >= 1: {
      const duration = timeDuration.months();
      expiration = `${duration} ${duration === 1 ? 'month' : 'months'}`;
      break;
    }
    case timeDuration.asWeeks() >= 1: {
      const duration = timeDuration.asWeeks();
      expiration = `${duration} ${duration === 1 ? 'week' : 'weeks'}`;
      break;
    }
    case timeDuration.days() >= 1: {
      const duration = timeDuration.days();
      expiration = `${duration} ${duration === 1 ? 'day' : 'days'}`;
      break;
    }
    case timeDuration.hours() >= 1: {
      const duration = timeDuration.hours();
      expiration = `${duration} ${duration === 1 ? 'hour' : 'hours'}`;
      break;
    }
    default: {
      const duration = timeDuration.minutes();
      expiration = `${duration} ${duration === 1 ? 'min' : 'mins'}`;
      break;
    }
  }
  return expiration;
};

const formatExpirationDate = ({ date, label = 'Expires in' }) => {
  const timeDiff = moment(date).diff(moment());
  const timeDuration = moment.duration(timeDiff);
  const expiration = timeDifference(timeDuration);

  return `${label} ${expiration}`;
};
const formatFullDateUTC = (date) => moment(date).utc().format('MMM D, YYYY, HH:mm (UTCZZ)');

export {
  formatMonthShort,
  formatTransactionDate,
  formatFullLocalDate,
  formatMMDDYY,
  isPastDate,
  formatExpirationDate,
  formatFullDateUTC,
  isSoonDate,
};
