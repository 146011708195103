import { useCallback } from 'react';
import { LIST_PAGE_SIZE } from '../../../config/config';
import ListPaginator from '../../../global/ListPaginator';
import LoadingBar from '../../../global/LoadingBar';
import MyRewardsList from '../../../global/MyRewardsList/MyRewardsList';
import ZeroState from '../../../global/MyRewardsList/ZeroState';
import WandSparklesIcon from '../../../images/wand-sparkles.svg';
import { ROUTE_DEALS } from '../../../config/routes';
import { setActiveTitle } from '../../../utils/utils';

import './DealsWallet.scss';

const TITLES_VALUES = {
  ACTIVE_EMPTY_SATE: 'Claim deals and earn TrashieCash™ back when using them',
  USED_EMPTY_SATE: 'No deals have been marked as used',
  EXPIRED_EMPTY_SATE: 'No deals have expired',
};

const DealsWallet = ({ isLoading, listData, status, paginatior, onClickIsUsed }) => {
  const { totalItems, page, handlePageSelection } = paginatior;

  const isActiveTitle = useCallback(
    (statusValue) => setActiveTitle(statusValue, TITLES_VALUES),
    [],
  );

  const activeTitle = useCallback(isActiveTitle(status), [status]);

  if (isLoading) {
    return (<div className="DealsWallet__section"><LoadingBar className="DealsWallet__loader" /></div>);
  }

  return (
    <div className="DealsWallet__section">
      {!listData.length && (
        <ZeroState
          icon={WandSparklesIcon}
          title={activeTitle}
          buttonLabel="Browse all deals"
          buttonRoute={ROUTE_DEALS}
        />
      )}
      {!!listData.length && (
        <>
          <MyRewardsList
            rewardsList={listData}
            status={status}
            onClickIsUsed={onClickIsUsed}
          />
          {totalItems > LIST_PAGE_SIZE &&
            (
              <ListPaginator
                pageSize={LIST_PAGE_SIZE}
                totalItems={totalItems}
                currentPage={page}
                isLoading={isLoading}
                onPageSelection={handlePageSelection}
              />
            )}
        </>
      )}
    </div>
  );
};

export default DealsWallet;
