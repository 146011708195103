import classNames from 'classnames';

import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../Button';
import LoadingBar from '../LoadingBar';
import ChevronUpSvg from '../SvgComponents/ChevronUp_svg';

import './DashboardSection.scss';

const DashboardSection = ({
  anchor = '',
  title = '',
  subTitle = '',
  onClick,
  isLoading,
  error = '',
  noData = '',
  ctaTrackerProps = {},
  children,
  contained,
  className,
}) => (
  <div
    id={anchor}
    className={classNames('DashboardSection', className)}
  >
    <div className={classNames('DashboardSection__container', { contained })}>
      {(title || subTitle || onClick) && (
        <div className={classNames('DashboardSection__container--top', { contained })}>
          <div className="DashboardSection__container--top-text">
            {title && (
              <h2 className="DashboardSection__container--top-text-title">{title}</h2>
            )}
            {subTitle && (
              <p className="DashboardSection__container--top-text-subTitle">{subTitle}</p>
            )}
          </div>
          {onClick && (
            <Button
              size={BUTTON_SIZE.LARGE}
              type={BUTTON_TYPE.LINK_QUINARY}
              onClick={onClick}
              className="DashboardSection__container--top-cta"
              trackerProps={ctaTrackerProps}
            >
              <span>VIEW ALL</span>
              <ChevronUpSvg
                color="var(--trashie-primary-code-blue)"
                height={14}
              />
            </Button>
          )}
        </div>
      )}
      <div className="DashboardSection__container--content">
        {isLoading ? (
          <LoadingBar className="DashboardSection__container--loader" />
        ) : (
          error || noData || children
        )}
      </div>
    </div>
  </div>
);

export default DashboardSection;
