import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import DynamicDealsBanner from '../../global/DynamicDealsBanner';
import { isTrashieContainerPublicPath } from '../../utils/routes';

import './TrashieContainerPublic.scss';
import { setDocumentTitle } from '../../utils/utils';

const TrashieContainerPublic = ({
  children,
  leftContent = (<DynamicDealsBanner />),
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    setDocumentTitle();
  }, [pathname]);

  if (!isTrashieContainerPublicPath(pathname)) {
    return children;
  }

  return (
    <div className="TrashieContainerPublic">
      <div className="TrashieContainerPublic__content">
        {leftContent && (
          <div className="TrashieContainerPublic__content--left">
            {leftContent}
          </div>
        )}
        <div className="TrashieContainerPublic__content--right">
          {children}
        </div>
      </div>
    </div>
  );
};

export default TrashieContainerPublic;
