import { useCallback, useEffect, useState } from 'react';

import OffersList from './OffersList';
import PageContent from '../../global/PageContent';
import { getGenericError } from '../../utils/errors';
import { getOffers } from '../../utils/service';
import MarketingBanner from '../../global/MarketingBanner';
import { BROWSER_EXTENSION_URL } from '../../config/externalURL';
import { ROUTE_DASHBOARD } from '../../config/routes';
import LaptopPromosImage from '../../images/laptop-promos.png';

import './Offers.scss';

const Offers = () => {
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const loadOffers = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: offersError,
    } = await getOffers();

    if (offersError) {
      setListData([]);
      setError(offersError.message ?? getGenericError());
      setIsLoading(false);
      return;
    }

    setListData(data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadOffers();
  }, []);

  return (
    <PageContent
      title="Earn while shopping at"
      isLoading={isLoading}
      error={error}
      className="Offers"
      backButtonText="GO TO THE DASHBOARD"
      parentUrl={ROUTE_DASHBOARD}
      cta={(
        <MarketingBanner
          title="Install the Chrome Extension"
          description="Get the Chrome Extension and start earning at over 15,000 brands."
          ctaLabel="Install now"
          ctaURL={BROWSER_EXTENSION_URL}
          backgroundImage={LaptopPromosImage}
        />
      )}
    >
      {(listData.length && <OffersList offersList={listData} isDashBoard />) || (
        <span className="Ofers__noData">
          No offers available at the moment
        </span>
      )}
    </PageContent>
  );
};

export default Offers;
