const WALLET_TABS = {
  DEALS: 'deals',
  REWARDS: 'rewards',
};

const DEALS_AND_REWARDS_TYPES = Object.freeze({
  ACTIVE: 'active',
  USED: 'used',
  EXPIRED: 'expired',
  ALL: 'all',
});

const STATUS_BUTTON_ARRAY = [
  { type: DEALS_AND_REWARDS_TYPES.ACTIVE },
  { type: DEALS_AND_REWARDS_TYPES.USED },
  { type: DEALS_AND_REWARDS_TYPES.EXPIRED }];

const STATUS_BUTTON_ARRAY_GIFT_CARDS = [
  { type: DEALS_AND_REWARDS_TYPES.ACTIVE },
  { type: DEALS_AND_REWARDS_TYPES.USED }];

export {
  WALLET_TABS,
  DEALS_AND_REWARDS_TYPES,
  STATUS_BUTTON_ARRAY,
  STATUS_BUTTON_ARRAY_GIFT_CARDS,
};
