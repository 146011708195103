const RewardSummaryItems = ({
  summaryItems = [],
}) => (
  summaryItems.map(({
    id,
    icon,
    label,
    enabled,
  }) => (
    enabled ? (
      <div
        key={id}
        className="message"
      >
        <div className="icon-container">
          <img src={icon} alt={`${id} icon`} />
        </div>
        <p className="label-container">{label}</p>
      </div>
    ) : null
  ))
);

export default RewardSummaryItems;
