import { useState } from 'react';

import MyRewardItem from './MyRewardItem';

import './MyRewardsList.scss';

const MyRewardsList = ({ rewardsList, status, onClickIsUsed, showIsUsed }) => {
  const [itemCopied, setItemCopied] = useState(null);

  return (
    <div
      role="list"
      className="MyRewardsList"
    >
      <div className="MyRewardsList__rewardRow">
        {rewardsList.map((rewardItem) => (
          <MyRewardItem
            key={`MyRewardItem-${rewardItem.uid}`}
            status={status}
            rewardItem={rewardItem}
            onCopy={(uid) => setItemCopied(uid)}
            isCopied={itemCopied === rewardItem.uid}
            onClickIsUsed={onClickIsUsed}
            showIsUsed={showIsUsed}
          />
        ))}
      </div>
    </div>
  );
};

export default MyRewardsList;
