import { useCallback, useEffect, useState } from 'react';
import { getRewardsOffer } from '../../utils/service';
import DealCard from './DealCard';
import LoadingBar from '../LoadingBar';

import './DynamicDealsBanner.scss';

const PAGE = 1;
const PAGE_SIZE = 20;
const SORT = 'sortRank';
const EXCHANGE_VALUE_MAX = 0;

const DynamicDealsBanner = () => {
  const [dealList, setDealList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const hotDeals = useCallback(async () => {
    setIsLoading(true);
    const { data } = await getRewardsOffer({
      page: PAGE,
      pageSize: PAGE_SIZE,
      isHot: true,
      sort: SORT,
      exchangeValueMax: EXCHANGE_VALUE_MAX,
    });

    setDealList(data?.rewards || []);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    hotDeals();
  }, []);

  useEffect(() => {
    if (!isLoading && dealList.length > 0) {
      const container = document.querySelector('.DynamicDealsBanner__container');
      if (container) {
        const items = Array.from(container.children);

        items.forEach((item) => {
          const clone = item.cloneNode(true);
          container.appendChild(clone);
        });
      }
    }
  }, [isLoading, dealList]);

  return (
    <div className="DynamicDealsBanner">
      {isLoading ? (
        <div className="DynamicDealsBanner__loading">
          <LoadingBar />
        </div>
      ) : (
        <div className="DynamicDealsBanner__container">
          {dealList.map((dealItem) => (
            <DealCard key={dealItem?.uid} dealItem={dealItem} />
          ))}
        </div>
      )}
    </div>
  );
};

export default DynamicDealsBanner;
