import { useEffect, useState, useCallback, useRef } from 'react';

import { LIST_PAGE_SIZE, LIST_SORT_DIRECTION } from '../../config/config';
import { ANCHOR_MY_REWARDS, ROUTE_REWARDS, ROUTE_WALLET, TAB_SEARCH_PARAM } from '../../config/routes';
import { REWARDS_ORDER_OPTIONS } from '../../config/rewards';
import { WALLET_TABS, STATUS_BUTTON_ARRAY, DEALS_AND_REWARDS_TYPES } from '../../config/wallet';
import ListPaginator from '../../global/ListPaginator';
import LoadingBar from '../../global/LoadingBar';
import MyRewardsList from '../../global/MyRewardsList/MyRewardsList';
import ZeroState from '../../global/MyRewardsList/ZeroState';
import { getUserRewards } from '../../utils/service';
import ButtonBack from '../../global/ButtonBack/ButtonBack';
import ListHeaders from '../../global/ListHeaders';
import StatusTabs from '../../global/StatusTabs';
import { setActiveTitle } from '../../utils/utils';
import { isMobile } from '../../../../hooks/useBreakPoint';
import IconGem from '../../images/icon-gem.svg';

import './MyRewards.scss';

const DEFAULT_PAGE = 1;
const GO_TO_WALLET = 'GO TO WALLET';

const TITLES_VALUES = {
  ACTIVE_EMPTY_SATE: 'Use your trashiecash on premium rewards',
  USED_EMPTY_SATE: 'No premium rewards have been marked as used',
  EXPIRED_EMPTY_SATE: 'No premium rewards have expired',
};

const MyRewards = () => {
  const timeoutRef = useRef(null);
  const mobile = isMobile();

  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalItems, setTotalItems] = useState(0);
  const [status, setStatus] = useState(DEALS_AND_REWARDS_TYPES.ACTIVE);

  const modifyStatus = useCallback((rewardStatus) => {
    setStatus(rewardStatus);
    setPage(DEFAULT_PAGE);
  }, [status]);

  const isTheLastElementInPage = useCallback(
    (listDataValue, pageValue) => (listDataValue?.length === 1 && pageValue > 1),
    [],
  );

  const loadRewards = useCallback(async (rewardStatus, pageValue) => {
    setIsLoading(true);

    const {
      data: rewardsData,
      error: rewardsDataError,
    } = await getUserRewards({
      pageSize: LIST_PAGE_SIZE,
      page: pageValue,
      orderBy: REWARDS_ORDER_OPTIONS.REDEEMED_AT,
      orderType: LIST_SORT_DIRECTION.DESC,
      type: WALLET_TABS.REWARDS,
      status: rewardStatus,
    });

    if (rewardsDataError) {
      setTotalItems(0);
      setListData([]);
      setPage(DEFAULT_PAGE);
      setIsLoading(false);
      return;
    }

    const {
      count,
      result: rewards,
    } = rewardsData;

    setTotalItems(count);
    setListData(rewards);
    setIsLoading(false);

    const mainElement = document.getElementById(ANCHOR_MY_REWARDS);

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [page]);

  const handlePageSelection = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const onClickIsUsed = useCallback(() => {
    if (isTheLastElementInPage(listData, page)) {
      setPage((prevPage) => prevPage - 1);
      return;
    }
    loadRewards(status, page);
  }, [status, page, listData]);

  useEffect(() => {
    loadRewards(status, page);
  }, [page, status]);

  useEffect(() => (
    () => timeoutRef?.current && clearTimeout(timeoutRef.current)
  ), []);

  const isActiveTitle = useCallback(
    (statusValue) => setActiveTitle(statusValue, TITLES_VALUES),
    [],
  );

  const activeTitle = useCallback(isActiveTitle(status), [status]);

  return (
    <div id={ANCHOR_MY_REWARDS} className="MyRewards">
      <div className="MyRewards__content">
        <div className="MyRewards__content--container">
          {!mobile && (
            <>
              <ButtonBack
                route={`${ROUTE_WALLET}?${TAB_SEARCH_PARAM}=${WALLET_TABS.REWARDS}`}
              >
                {GO_TO_WALLET}
              </ButtonBack>
              <ListHeaders title="Premium Rewards" />
            </>
          )}

          <div className="MyRewards__content--section">
            <StatusTabs
              tabArray={STATUS_BUTTON_ARRAY}
              onClickAction={modifyStatus}
              status={status}
            />
            <div className="MyRewards__list">
              {isLoading && (
                <LoadingBar className="MyRewards__content--loader" />
              )}
              {!isLoading && !listData.length && (
                <ZeroState
                  icon={IconGem}
                  title={activeTitle}
                  buttonLabel="Browse premium rewards"
                  buttonRoute={ROUTE_REWARDS}
                />
              )}
              {!isLoading && !!listData.length && (
                <>
                  <MyRewardsList
                    rewardsList={listData}
                    status={status}
                    onClickIsUsed={onClickIsUsed}
                  />
                  {totalItems > LIST_PAGE_SIZE &&
                    (
                      <ListPaginator
                        pageSize={LIST_PAGE_SIZE}
                        totalItems={totalItems}
                        currentPage={page}
                        isLoading={isLoading}
                        onPageSelection={handlePageSelection}
                      />
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRewards;
