import { useCallback, useEffect, useState } from 'react';

import RewardsPreview from '../Rewards/RewardsPreview';
import GiftCardsPreview from '../GiftCards/GiftCardsPreview';
import useScrollToSection from '../../../../hooks/useScrollToSection';
import { getEarningsCarousel } from '../../utils/service';
import Slider from '../../global/Slider';
import { TRASHIE_WEBSITE_URL } from '../../config/externalURL';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../config/tracker';
import { isMobile, SIZE_SM, SIZE_XS } from '../../../../hooks/useBreakPoint';
import useHasAccessToGiftCards from '../../../../hooks/useHasAccessToGiftCards';

import './DashboardRewards.scss';

const {
  dashboard: {
    marketing: {
      slide: trackerSlide,
    },
  },
} = TRACKER_IDS;

const DashboardRewards = () => {
  useScrollToSection();
  const isMobileXsSm = isMobile([SIZE_XS, SIZE_SM]);
  const {
    isLoading: isLoadingHasAccessToGiftCards,
    hasAccessToGiftCards,
  } = useHasAccessToGiftCards();

  const [carousel, setCarousel] = useState([]);
  const [isLoadingCarousel, setIsLoadingCarousel] = useState(true);

  const loadCarousel = useCallback(async () => {
    setIsLoadingCarousel(true);

    const {
      data,
      error: loadCarouselError,
    } = await getEarningsCarousel();

    if (loadCarouselError) {
      setCarousel([]);
      setIsLoadingCarousel(false);
      return;
    }

    setCarousel(data);
    setIsLoadingCarousel(false);
  }, []);

  useEffect(() => {
    loadCarousel();
  }, []);

  return (
    <div className="DashboardRewards">
      <div className="DashboardRewards__container">
        {!isLoadingCarousel && carousel.length > 0 && (
          <div className="DashboardRewards__container--slider">
            <Slider
              slides={carousel.map(({
                name,
                desktopImageUrl,
                mobileImageUrl,
                redirectUrl,
                posthogId,
              }) => ({
                key: name,
                slide: (
                  <a
                    href={redirectUrl || TRASHIE_WEBSITE_URL}
                    alt="slide"
                    className="DashboardRewards__container--slider-slide"
                    {...{
                      [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerSlide),
                      [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: posthogId,
                    }}
                  >
                    <img
                      src={(isMobileXsSm && mobileImageUrl) || desktopImageUrl}
                      alt="slide"
                    />
                  </a>
                ),
              }))}
            />
          </div>
        )}
        <RewardsPreview />
        {!isLoadingHasAccessToGiftCards && hasAccessToGiftCards && (
          <GiftCardsPreview />
        )}
      </div>
    </div>
  );
};

export default DashboardRewards;
