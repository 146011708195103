import { useMemo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { ROUTE_HOME } from '../../config/routes';
import trashieLogo from '../../images/trashie-logo.webp';
import { isMobile } from '../../../../hooks/useBreakPoint';
import {
  getCurrentRouteParentRoute,
  getCurrentRouteTitle,
  NAVIGATION_ITEMS,
} from '../../utils/routes';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import HeaderAccount from './HeaderAccount';
import NavigationBar from '../NavigationBar';

import styles from './Header.module.scss';

const {
  main,
  shadow,
  container,
  logo,
  img,
  title,
  ctaContainer,
  cta,
  ctaActive,
} = styles;

const Header = () => {
  const { isSignedIn, userData } = useFirebaseAuth();
  const location = useLocation();
  const history = useHistory();
  const mobile = isMobile();

  const { parentRoute, routeTitle } = useMemo(() => ({
    parentRoute: getCurrentRouteParentRoute(),
    routeTitle: getCurrentRouteTitle(true),
  }), [location.pathname]);

  return (
    <div>
      <nav className={`${main} ${mobile && parentRoute ? '' : shadow}`}>
        <div className={container}>
          {mobile ? (
            (parentRoute && (
              <NavigationBar
                title={routeTitle}
                onGoBack={() => history.push(parentRoute)}
              />
            )) || (
              <span className={title}>{routeTitle}</span>
            )
          ) : (
            <Link className={logo} to={ROUTE_HOME}>
              <img className={img} src={trashieLogo} alt="trashie logo" />
            </Link>
          )}
          {isSignedIn && userData && (
            <>
              {!mobile && (
                <div className={ctaContainer}>
                  {NAVIGATION_ITEMS.map(({ route, label, icon: Icon }) => (
                    <Link
                      className={`${cta} ${location.pathname === route ? ctaActive : ''}`}
                      to={location.pathname === route ? location : route}
                      key={`headerItem${label}`}
                    >
                      <Icon
                        height={16}
                        width={16}
                        color={route === location.pathname ? 'var(--trashie-primary-code-blue)' : 'var(--trashie-ui-faded-black-100-b)'}
                      />
                      <span>{label}</span>
                    </Link>
                  ))}
                </div>
              )}
              {mobile && parentRoute ? (
                null
              ) : (
                <HeaderAccount />
              )}
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Header;
