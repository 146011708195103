import { useCallback, useEffect, useState } from 'react';

import { useFirebaseAuth } from '../components/global/FirebaseProvider/FirebaseProvider';
import { getConfigurations } from '../components/trashie/utils/service';

export const TBB_SEGMENTS_TYPES = {
  TBB_USERS: 'tbb-users',
  NON_TBB_USERS: 'non-tbb-users',
};

export const isNonTbbUser = userType => userType === TBB_SEGMENTS_TYPES.NON_TBB_USERS;

const useDealsSegmentation = () => {
  const { userData, isLoading: isLoadingFirebaseAuth, isSignedIn } = useFirebaseAuth();

  const [isLoadingDealsSegmentation, setIsLoadingDealsSegmentation] = useState(true);
  const [dealsSegmentation, setDealsSegmentation] = useState({});

  const loadTBBUserSegments = useCallback(async () => {
    setIsLoadingDealsSegmentation(true);
    const { value, error } = await getConfigurations('dealsSegmentation');

    if (error) {
      setDealsSegmentation({});
      setIsLoadingDealsSegmentation(false);
      return;
    }

    setDealsSegmentation(value);
    setIsLoadingDealsSegmentation(false);
  }, []);

  const getUserType = useCallback(() => {
    const userSegments = userData?.segments || [];
    const {
      enabled: dealsSegmentationEnabled,
      segments: {
        [TBB_SEGMENTS_TYPES.NON_TBB_USERS]: nonTbbUsersSegment,
      } = {},
    } = dealsSegmentation;

    switch (true) {
      case userSegments.includes(nonTbbUsersSegment):
        return TBB_SEGMENTS_TYPES.NON_TBB_USERS;
      case !dealsSegmentationEnabled:
      default:
        return TBB_SEGMENTS_TYPES.TBB_USERS;
    }
  }, [userData, dealsSegmentation]);

  useEffect(() => {
    if (!isLoadingFirebaseAuth && isSignedIn) {
      loadTBBUserSegments();
    }
  }, [isLoadingFirebaseAuth, isSignedIn]);

  return {
    isLoading: isLoadingFirebaseAuth || isLoadingDealsSegmentation,
    isNonTbbUser: isNonTbbUser(getUserType()),
    dashboardCategories: dealsSegmentation.dashboardCategories ?? [],
  };
};

export default useDealsSegmentation;
