import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { formatMMDDYY } from '../../utils/dates';
import LoadingBar from '../../global/LoadingBar';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../global/Button';
import ImpactMetrics from '../../global/ImpactMetrics';
import { ROUTE_LOGOUT, ROUTE_PROFILE, ROUTE_TAKE_BACK_BAGS, ROUTE_TRANSACTIONS } from '../../config/routes';
import ItemListContainer from '../../global/ItemListContainer/ItemListContainer';
import { useAlert } from '../../../providers/AlertProvider';
import { FAQS_URL, HELP_URL } from '../../config/externalURL';
import TrashieEyesIcon from '../../images/trashie-eyes.webp';
import ArrowLeftRightIcon from '../../images/arrow-left-right-grey.svg';
import HelpCircleIcon from '../../images/help-circle-grey.svg';
import UserIcon from '../../images/user-circle.svg';
import BadgeInfoIcon from '../../images/badge-info.svg';
import Package from '../../images/package.svg';
import ChevronRightIcon from '../../images/chevron-right.svg';
import ArrowUpIcon from '../../images/arrow-left-right-up.svg';

import './MyAccount.scss';

const MY_STUFF = [
  {
    label: 'View your TrashieCash™ activity',
    route: ROUTE_TRANSACTIONS,
    icon: ArrowLeftRightIcon,
    title: 'Transactions',
  },
  {
    label: 'Access your shipping labels & Take Back order details',
    route: ROUTE_TAKE_BACK_BAGS,
    icon: Package,
    title: 'My shipping labels',
  },
  {
    label: 'Update your info',
    route: ROUTE_PROFILE,
    icon: UserIcon,
    title: 'Account Information',
  },
];

const MY_SUPPORT = [
  {
    label: 'Quick answers to common questions',
    route: FAQS_URL,
    external: true,
    icon: HelpCircleIcon,
    title: 'FAQs',
  },
  {
    label: 'Detailed information',
    route: HELP_URL,
    external: true,
    icon: BadgeInfoIcon,
    title: 'Help Center',
  },
];

const MyAccount = () => {
  const { userData, isLoading: isLoadingFirebaseAuth } = useFirebaseAuth();
  const history = useHistory();
  const setAlert = useAlert();

  useEffect(() => {
    if (isLoadingFirebaseAuth) {
      return;
    }

    if (userData?.error) {
      setAlert(userData.error);
    }
  }, [userData?.error, isLoadingFirebaseAuth]);

  if (isLoadingFirebaseAuth) {
    return (
      <div className="MyAccount">
        <div className="MyAccount__container">
          <LoadingBar />
        </div>
      </div>
    );
  }

  return (
    <div className="MyAccount">
      <div className="MyAccount__container">
        <div className="MyAccount__contentAccount">
          <div className="MyAccount__header">
            <div className="MyAccount__image-container">
              <img className="MyAccount__image" src={TrashieEyesIcon} alt="edit" />
            </div>
            <div className="MyAccount__header-container">
              <div className="MyAccount__userTitle">{userData.firstName ? `Hi, ${userData.firstName}` : 'Account'}</div>
              <div className="MyAccount__infoTitle">{userData.email}</div>
              <div className="MyAccount__infoTitle">{`Member Since: ${formatMMDDYY(userData.memberSince)}`}</div>
            </div>
            <div className="MyAccount__button-container">
              <Button
                type={BUTTON_TYPE.LINK}
                size={BUTTON_SIZE.MEDIUM}
                className="MyAccount__button-logout"
                onClick={() => {
                  history.push(ROUTE_LOGOUT);
                }}
              >
                LOGOUT
              </Button>
            </div>
          </div>
          <ImpactMetrics />
          <ItemListContainer listTitle="My Stuff" history={history} items={MY_STUFF} exitIcon={ChevronRightIcon} />
          <ItemListContainer listTitle="Support" history={history} items={MY_SUPPORT} exitIcon={ArrowUpIcon} />
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
