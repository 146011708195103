import LogRocket from 'logrocket';

import { PROMO_ID_PARAM, WF_DEVICE_ID_SEARCH_PARAM } from '../config/routes';
import { EVENT_STREAM_APP_TYPES, EVENT_STREAM_EVENT_TYPES } from '../config/service';
import { getSearchParam } from './routes';
import { sendEventStream } from './service';
import { getUserName, getUUID } from './utils';
import { POSTHOG_PROPERTIES, TRACKER_EVENT_TYPES } from '../config/tracker';

export const SIGNUP_METHOD = {
  MANUAL: 'manual',
  GOOGLE: 'google',
};

export const SOURCE = {
  APP: 'app',
  EMBED: 'embed',
};

export const TOPIC_SIGNUP = {
  TBB_REGISTRATION_SIGNUP_FLOW: 'TBB_REGISTRATION_SIGNUP_FLOW',
  SIGN_UP_WITH_LOGIN_LINK: 'SIGN_UP_WITH_LOGIN_LINK',
  SIGN_UP_WEB_FLOW_LANDING_PAGE: 'SIGN_UP_WEB_FLOW_LANDING_PAGE',
};

export const finishProcess = async ({
  userRegistered = false,
  userRegisteredMethod = SIGNUP_METHOD.MANUAL,
  userData = {},
  optIn = false,
  search = '',
  wfExtensionFlow = false,
  wfedidParam,
  posthog,
  source = SOURCE.APP,
  trackerProps = {},
}) => {
  const {
    email,
  } = userData;

  const uuid = getUUID(userData);

  if (userRegistered) {
    window.gtag?.('event', 'sign_up', { method: userRegisteredMethod, source });
    window.fbq?.('trackCustom', 'sign_up');

    if (posthog) {
      posthog.capture(
        TRACKER_EVENT_TYPES.SIGNUP,
        {
          [POSTHOG_PROPERTIES.METHOD]: userRegisteredMethod,
          [POSTHOG_PROPERTIES.SOURCE]: source,
          [POSTHOG_PROPERTIES.SUBSCRIBE]: optIn,
          ...trackerProps,
          $set: { email },
        },
      );
    }

    const query = new URLSearchParams(search);
    const promoId = getSearchParam(query, PROMO_ID_PARAM);

    if (userRegisteredMethod !== SIGNUP_METHOD.GOOGLE) {
      await sendEventStream({
        event: EVENT_STREAM_EVENT_TYPES.USER_REGISTERED,
        application: EVENT_STREAM_APP_TYPES.TRASHIE,
        data: {
          method: userRegisteredMethod,
          source,
          shouldSubscribe: optIn,
          ...(promoId && { promoId }),
        },
      });
    }
  }

  if (posthog) {
    posthog.identify(
      uuid,
      {
        email,
        [WF_DEVICE_ID_SEARCH_PARAM]: wfedidParam,
      },
    );

    if (wfedidParam) {
      posthog.alias(wfedidParam, uuid);
    }

    posthog.capture(
      TRACKER_EVENT_TYPES.LOGIN,
      {
        [POSTHOG_PROPERTIES.METHOD]: userRegisteredMethod,
        [POSTHOG_PROPERTIES.SOURCE]: source,
        ...trackerProps,
        $set: { email },
      },
    );
  }

  LogRocket.identify(String(uuid), {
    email,
    name: getUserName(userData),
  });

  await sendEventStream({
    event: wfExtensionFlow ?
      EVENT_STREAM_EVENT_TYPES.USER_LOGGED_IN_WF_EXTENSION :
      EVENT_STREAM_EVENT_TYPES.USER_LOGGED_IN,
    application: EVENT_STREAM_APP_TYPES.TRASHIE,
  });
};
