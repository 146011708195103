import { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Box } from '@mui/material';

import { LIST_PAGE_SIZE, LIST_SORT_DIRECTION } from '../../config/config';
import {
  ANCHOR_WALLET,
  ROUTE_WALLET,
  TAB_SEARCH_PARAM,
} from '../../config/routes';
import { REWARDS_ORDER_OPTIONS } from '../../config/rewards';
import { WALLET_TABS, DEALS_AND_REWARDS_TYPES, STATUS_BUTTON_ARRAY } from '../../config/wallet';
import { getSearchParam } from '../../utils/routes';
import { isDeal, isReward } from '../../utils/utils';
import { getUserGiftCards, getUserRewards } from '../../utils/service';
import StatusTabs from '../../global/StatusTabs';
import Tabs from '../../global/Mui/Tabs';
import Tab from '../../global/Mui/Tab';
import { isProd } from '../../../../config/config';
import DealsWallet from './DealsWallet';
import RewardsWallet from './RewardsWallet';

import './Wallet.scss';
import useHasAccessToGiftCards from '../../../../hooks/useHasAccessToGiftCards';

const DEFAULT_PAGE = 1;

const PREVIEW_PAGE_SIZE = 2;

const Wallet = () => {
  const timeoutRef = useRef(null);
  const history = useHistory();
  const { search } = useLocation();
  const { hasAccessToGiftCards } = useHasAccessToGiftCards();

  const query = new URLSearchParams(search);
  const tabSearchParam = getSearchParam(query, TAB_SEARCH_PARAM);

  const [tab, setTab] = useState(tabSearchParam || WALLET_TABS.DEALS);
  const [listData, setListData] = useState([]);
  const [giftCardsListData, setGiftCardsListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGiftCards, setIsLoadingGiftCards] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalItems, setTotalItems] = useState(0);
  const [status, setStatus] = useState(DEALS_AND_REWARDS_TYPES.ACTIVE);

  const modifyStatus = useCallback(
    (rewardStatus) => {
      setStatus(rewardStatus);
      setPage(DEFAULT_PAGE);
    },
    [status],
  );

  const isTheLastElementInPage = useCallback(
    (listDataValue, pageValue) => (listDataValue?.length === 1 && pageValue > 1),
    [],
  );

  const loadRewards = useCallback(async (type, rewardStatus, pageValue) => {
    setIsLoading(true);

    const {
      data: rewardsData,
      error: rewardsDataError,
    } = await getUserRewards({
      pageSize: isDeal(type) ? LIST_PAGE_SIZE : PREVIEW_PAGE_SIZE,
      page: pageValue,
      orderBy: REWARDS_ORDER_OPTIONS.REDEEMED_AT,
      orderType: LIST_SORT_DIRECTION.DESC,
      type,
      status: isDeal(type) ? rewardStatus : DEALS_AND_REWARDS_TYPES.ALL,
    });

    if (rewardsDataError) {
      setTotalItems(0);
      setListData([]);
      setPage(DEFAULT_PAGE);
      setIsLoading(false);
      return;
    }

    const {
      count,
      result: rewards,
    } = rewardsData;

    setTotalItems(count);
    setListData(rewards);
    setIsLoading(false);

    const mainElement = document.getElementById(ANCHOR_WALLET);

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [page]);

  const loadGiftCards = useCallback(async (type) => {
    if (isDeal(type)) {
      setGiftCardsListData([]);
      setIsLoadingGiftCards(true);
      return;
    }

    setIsLoadingGiftCards(true);

    const {
      data: giftCardsData,
      error: giftCardsDataError,
    } = await getUserGiftCards({
      pageSize: PREVIEW_PAGE_SIZE,
      page,
      orderBy: REWARDS_ORDER_OPTIONS.REDEEMED_AT,
      orderType: LIST_SORT_DIRECTION.DESC,
      status: DEALS_AND_REWARDS_TYPES.ALL,
    });

    if (giftCardsDataError) {
      setGiftCardsListData([]);
      setIsLoadingGiftCards(false);
      return;
    }

    setGiftCardsListData(giftCardsData.giftCardOrders);
    setIsLoadingGiftCards(false);
  }, [page]);

  const updateSearchParam = useCallback((newTab = WALLET_TABS.DEALS) => {
    query.set(TAB_SEARCH_PARAM, newTab);

    history.push({
      pathname: ROUTE_WALLET,
      search: query.toString(),
    });
  }, [query, search]);

  const handleTabChange = useCallback((_, newTab) => {
    setTab(newTab);
    setTotalItems(0);
    setListData([]);
    setPage(DEFAULT_PAGE);
    setIsLoading(false);
    setStatus(DEALS_AND_REWARDS_TYPES.ACTIVE);

    updateSearchParam(newTab);
  }, [updateSearchParam]);

  const handlePageSelection = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const onClickIsUsed = useCallback(() => {
    if (isTheLastElementInPage(listData, page)) {
      setPage((prevPage) => prevPage - 1);
      return;
    }
    loadRewards(tab, status, page);
  }, [tab, status, page, listData]);

  useEffect(() => {
    loadRewards(tab, status, page);
    loadGiftCards(tab);
  }, [page, tab, status]);

  useEffect(() => {
    if (!tabSearchParam) {
      updateSearchParam();
    }

    return () => timeoutRef?.current && clearTimeout(timeoutRef.current);
  }, []);

  return (
    <div id={ANCHOR_WALLET} className="Wallet">
      <div className="Wallet__content">
        <div className="Wallet__content--container">
          <Box
            className={classNames('Wallet__content--tabs', { hasEnvBanner: !isProd() })}
          >
            <Tabs
              value={tab}
              onChange={handleTabChange}
              variant="fullWidth"
              aria-label="rewards tabs"
            >
              {(Object.values(WALLET_TABS)).map(tabId => (
                <Tab
                  key={tabId}
                  value={tabId}
                  label={tabId}
                />
              ))}
            </Tabs>
          </Box>
          <div className="Wallet__content--section">
            {isDeal(tab) && (
              <>
                <StatusTabs
                  tabArray={STATUS_BUTTON_ARRAY}
                  onClickAction={modifyStatus}
                  status={status}
                />
                <DealsWallet
                  isLoading={isLoading}
                  status={status}
                  listData={listData}
                  paginatior={{ totalItems, page, handlePageSelection }}
                  onClickIsUsed={onClickIsUsed}
                />
              </>
            )}
            {isReward(tab) && (
              <RewardsWallet
                isLoading={isLoading}
                isLoadingGiftCards={isLoadingGiftCards}
                listData={listData}
                history={history}
                hasAccessToGiftCards={hasAccessToGiftCards}
                giftCardsListData={giftCardsListData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
