import { useEffect, useState, useCallback, useRef } from 'react';

import { LIST_PAGE_SIZE, LIST_SORT_DIRECTION } from '../../config/config';
import { ANCHOR_GIFT_CARDS, ROUTE_GIFT_CARDS, ROUTE_WALLET, TAB_SEARCH_PARAM } from '../../config/routes';
import { REWARDS_ORDER_OPTIONS } from '../../config/rewards';
import { WALLET_TABS, STATUS_BUTTON_ARRAY_GIFT_CARDS, DEALS_AND_REWARDS_TYPES } from '../../config/wallet';
import ListPaginator from '../../global/ListPaginator';
import LoadingBar from '../../global/LoadingBar';
import MyGiftCardsList from '../../global/MyGiftCardsList';
import ZeroState from '../../global/MyRewardsList/ZeroState';
import { getUserGiftCards } from '../../utils/service';
import ButtonBack from '../../global/ButtonBack/ButtonBack';
import ListHeaders from '../../global/ListHeaders';
import { isMobile } from '../../../../hooks/useBreakPoint';
import WalletIcon from '../../images/wallet-icon.svg';
import StatusTabs from '../../global/StatusTabs';
import { setActiveTitle } from '../../utils/utils';

import './MyGiftCards.scss';

const DEFAULT_PAGE = 1;
const GO_TO_WALLET = 'GO TO WALLET';
const TITLES_VALUES = {
  ACTIVE_EMPTY_SATE: 'REDEEM TRASHIECASH ON AWESOME GIFT CARDS',
  USED_EMPTY_SATE: 'NO GIFT CARDS HAVE BEEN MARKED AS USED',
};

const MyGiftCards = () => {
  const timeoutRef = useRef(null);
  const mobile = isMobile();

  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalItems, setTotalItems] = useState(0);
  const [status, setStatus] = useState(DEALS_AND_REWARDS_TYPES.ACTIVE);

  const modifyStatus = useCallback((giftCardStatus) => {
    setStatus(giftCardStatus);
    setPage(DEFAULT_PAGE);
  }, [status]);

  const isTheLastElementInPage = useCallback(
    (listDataValue, pageValue) => (listDataValue?.length === 1 && pageValue > 1),
    [],
  );

  const loadGiftCards = useCallback(async (pageValue, giftCardStatus) => {
    setIsLoading(true);

    const {
      data: giftCardsData,
      error: giftCardsDataError,
    } = await getUserGiftCards({
      pageSize: LIST_PAGE_SIZE,
      page: pageValue,
      orderBy: REWARDS_ORDER_OPTIONS.REDEEMED_AT,
      orderType: LIST_SORT_DIRECTION.DESC,
      status: giftCardStatus,
    });

    if (giftCardsDataError) {
      setTotalItems(0);
      setListData([]);
      setPage(DEFAULT_PAGE);
      setIsLoading(false);
      return;
    }

    const {
      total,
      giftCardOrders,
    } = giftCardsData;

    setTotalItems(total);
    setListData(giftCardOrders);
    setIsLoading(false);

    const mainElement = document.getElementById(ANCHOR_GIFT_CARDS);

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [page, status]);

  const handlePageSelection = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  useEffect(() => {
    loadGiftCards(page, status);
  }, [page, status]);

  useEffect(() => (
    () => timeoutRef?.current && clearTimeout(timeoutRef.current)
  ), []);

  const onClickIsUsed = useCallback(() => {
    if (isTheLastElementInPage(listData, page)) {
      setPage((prevPage) => prevPage - 1);
      return;
    }
    loadGiftCards(page, status);
  }, [page, status, listData]);

  const isActiveTitle = useCallback(
    (statusValue) => setActiveTitle(statusValue, TITLES_VALUES),
    [],
  );

  const activeTitle = useCallback(isActiveTitle(status), [status]);

  if (isLoading) {
    return (
      <div id={ANCHOR_GIFT_CARDS} className="MyGiftCards">
        <div className="MyGiftCards__content">
          <div className="MyGiftCards__content--container">
            <div className="MyGiftCards__content--section">
              <div className="MyGiftCards__list">
                <LoadingBar className="MyGiftCards__content--loader" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id={ANCHOR_GIFT_CARDS} className="MyGiftCards">
      <div className="MyGiftCards__content">
        <div className="MyGiftCards__content--container">
          {!mobile && (
            <>
              <ButtonBack
                route={`${ROUTE_WALLET}?${TAB_SEARCH_PARAM}=${WALLET_TABS.REWARDS}`}
              >
                {GO_TO_WALLET}
              </ButtonBack>
              <ListHeaders title="Gift Cards" />
            </>
          )}
          <div className="MyGiftCards__content--section">
            <StatusTabs
              tabArray={STATUS_BUTTON_ARRAY_GIFT_CARDS}
              onClickAction={modifyStatus}
              status={status}
            />
            <div className="MyGiftCards__list">

              {!listData.length ? (
                <ZeroState
                  icon={WalletIcon}
                  title={activeTitle}
                  buttonLabel="Browse premium rewards"
                  buttonRoute={ROUTE_GIFT_CARDS}
                />
              ) : (<MyGiftCardsList onClickIsUsed={onClickIsUsed} giftCardsList={listData} />)}
              {totalItems > LIST_PAGE_SIZE &&
                (
                  <ListPaginator
                    pageSize={LIST_PAGE_SIZE}
                    totalItems={totalItems}
                    currentPage={page}
                    isLoading={isLoading}
                    onPageSelection={handlePageSelection}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyGiftCards;
