import { useCallback } from 'react';
import { Input } from '@mui/material';
import searchIcon from '../../../images/search-icon.svg';
import './RewardsSearchInput.scss';
import useSearchPlaceHolderText from '../../../../../hooks/useSearchPlaceHolderText';

const RewardsSearchInput = ({
  setIsSearching,
  clearFiltersAndResults,
  searchType,
}) => {
  const placeHolderText = useSearchPlaceHolderText(searchType);

  const onOpenModal = useCallback(() => {
    setIsSearching();
    clearFiltersAndResults();
  }, []);

  return (
    <div className="RewardsSearchInput__input-container">
      <Input
        className="RewardsSearchInput__input"
        placeholder={placeHolderText}
        onClick={onOpenModal}
        disableUnderline
        readOnly
      />
      <img
        className="RewardsSearchInput__search-icon"
        src={searchIcon}
        alt="search-icon"
      />
    </div>

  );
};

export default RewardsSearchInput;
