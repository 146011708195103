import './DealCard.scss';

const DealCard = ({ dealItem }) => {
  const { store } = dealItem || {};

  return (
    <div
      className="DealCard"
    >
      <div
        className="DealCard__container"
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%), url(${store?.imgUrl}) lightgray 50% / cover no-repeat`,
        }}
      >
        <img src={store?.logoUrl} alt="logo" className="DealCard__container--logo" />
      </div>
    </div>
  );
};

export default DealCard;
