import InvalidIcon from '../../../../images/invalid-icon.svg';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import { isTechRecyclingProductType, getNextButtonStyles, getOptionButtonStyles } from '../../../../utils/tbbRegistration';

import './Invalid.scss';

const Invalid = ({
  onClick,
  storeConfig = {},
  message,
  recyclingProductType,
  code,
  onCancel,
}) => (
  <div className="RegisterInvalid">
    <div className="RegisterInvalid__message">
      <div className="RegisterInvalid__message--logo">
        <img
          src={InvalidIcon}
          alt="invalid icon"
        />
      </div>
      <div className="RegisterInvalid__message--title">
        {message ?? `${isTechRecyclingProductType(recyclingProductType) ? 'BOX' : 'BAG'} CODE NOT FOUND`}
      </div>
      <div className="RegisterInvalid__message--subtitle">
        Try a different one or contact support.
      </div>
    </div>

    <div className="RegisterInvalid__code">
      {code}
    </div>

    <Button
      type={BUTTON_TYPE.QUATERNARY}
      size={BUTTON_SIZE.LARGE}
      onClick={onClick}
      style={getNextButtonStyles(storeConfig)}
    >
      TRY A DIFFERENT CODE
    </Button>
    <Button
      type={BUTTON_TYPE.QUINARY}
      size={BUTTON_SIZE.LARGE}
      onClick={onCancel}
      style={getOptionButtonStyles(storeConfig)}
    >
      CONTACT SUPPORT
    </Button>
  </div>
);

export default Invalid;
